import React from "react";
import { IoMdCloseCircle } from "react-icons/io";
import FilterDetail from "./Detail";

const DateRangeFilter = ({
  isDateFilterVisible,
  GetResponseData,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isSearchFilterVisible,
  isMonthFilterVisible,
  isSingleDateFilterVisible,
  isMonthRangeFilterVisible,
  handleDateFilterToggle,
  status,
  setStatus,
  waiting,
  setWaiting,
  invoice,
  setInvoice,
  arrangement,
  setArrangement,
  setLoading
}) => {
  

  return (
    <>
      {/* Start Filter Date */}
      <div className="p-3">
        <div className="is-flex is-align-items-center" style={{ marginTop: "10px" }}>
       
          <div className="is-flex is-align-items-center">
            <div className="field has-addons is-flex is-align-items-center">
              {isDateFilterVisible && (
                <>
                  <div className="control mr-2">
                    <label className="label is-small">Start Date</label>
                    <input
                      className="input is-small"
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      placeholder="Start Date"
                    />
                  </div>
                  <div className="control mr-2 mt-4" style={{ display: "flex", alignItems: "center" }}>
                    <span>-</span>
                  </div>
                  <div className="control mr-2">
                    <label className="label is-small">End Date</label>
                    <input
                      className="input is-small"
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      placeholder="End Date"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          {isDateFilterVisible && (
            <>
              <div className="control mr-2 mt-4">
                <FilterDetail 
                  GetResponseData={GetResponseData} 
                  status={status} 
                  setStatus={setStatus} 
                  arrangement={arrangement}
                  setArrangement={setArrangement} 
                  waiting={waiting}
                  setWaiting={setWaiting}
                  invoice={invoice}
                  setInvoice={setInvoice}
                  setLoading={setLoading}
                />
              </div>
            </>
          )}
          <div className={isDateFilterVisible ? "buttons is-flex is-justify-content-center ml-2" : ""}>
            {!isSearchFilterVisible && !isMonthFilterVisible && !isSingleDateFilterVisible && !isMonthRangeFilterVisible && (
              <button
                className={`button is-small ${isDateFilterVisible ? "is-danger" : "is-info"}`}
                onClick={ handleDateFilterToggle }
              >
                {isDateFilterVisible ? <IoMdCloseCircle style={{ fontSize: "23px" }} /> : "Filter Appointment"}
              </button>
            )}
          </div>
        </div>
      </div>
      {/* End Filter Date */}
    </>
  );
};

export default DateRangeFilter;
