import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useMutation } from "react-query";
import swal from "sweetalert";
import LoaderAction from "../../Loader/LoaderAction";
import { IoCheckbox } from "react-icons/io5";
import { API } from "../../../config/api";
import { FaTimes } from "react-icons/fa";

export default function ModalRolePaymentType(props) {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [getDataPaymentType, setGetDataPaymentType] = useState([]);
  const [getData, setGetResponseData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [propsData, setProopsData] = useState()
  const [loading, setLoading] = useState(false);

  const fetchParams = {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  };

  const handleClose = () => {
    setSelectedId(null); 
    props.onHide();
  };

  

  const GetResponsePaymentType = () => {
    setGetDataPaymentType(props?.GetResponsePaymentType || []);
  };

  const handleSubmit = useMutation(async (id) => {
    try {
      setLoading(true);
      const response = await API.put(
        `transaction-service/fees/${props.id}`,
        {
          id_payment_type: id, 
          name: props?.nameUpdate,
          price: props?.NumberUpdate,
        },

        fetchParams
      );

  
      if (response?.status === 200) {
        swal({
          title: "Success",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          buttons: false,
        });
        props.GetResponseData()
        // getDataPaymentType();
        props.onHide(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      swal({
        title: "Failed",
        text: `${error.response.data.message}`,
        icon: "error",
        timer: 3000,
        buttons: false,
      });
    }
  });
  

  useEffect(() => {
    GetResponsePaymentType();
  }, [props]);

  return (
    <div>
      {loading && <LoaderAction />}
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{ fontFamily: "sans-serif", border: "none" }}>
          <div style={{ width: "100%", display: "flex", padding: "10px 0px", backgroundColor: "#29B8FF" }}>
            <div style={{ flex: "92%", fontSize: "20px", display: "flex", alignItems: "center", paddingLeft: "10px", color: "white", fontWeight: "600" }}>
              Tipe Pembayaran
            </div>
            <div style={{ flex: "8%", fontSize: "30px", display: "flex", alignItems: "center", justifyContent: "center", color: "white" }}>
              <FaTimes onClick={() => setProopsData(props.onHide)} style={{ cursor: "pointer" }} />
            </div>
          </div>
          <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "none" }}>
          <table className="table is-bordered">
            <thead>
              <tr style={{ backgroundColor: "#E9EFFF" }}>
                <th style={{ textAlign: "center" }}>No</th>
                <th style={{ textAlign: "center" }}>Tipe Pembayaran</th>
                <th style={{ textAlign: "center" }}>Setup</th>
              </tr>
            </thead>
            <tbody>
              {getDataPaymentType.map((user, index) => {
                const isSelected = selectedId === user?.id || props?.IdPaymentType === user?.id;
                return (
                  <tr key={index} style={{ textAlign: "center" }}>
                    <td>{index + 1}</td>
                    <td>{user.name}</td>
                    <td>
                      <button
                        style={{
                          backgroundColor: isSelected ? "#4caf50" : "#e9e9e9",
                          color: "#fff",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSelectedId(user?.id);
                          handleSubmit.mutate(user?.id);
                        }}
                      >
                        <IoCheckbox style={{ textAlign: "center", alignItems: "center" }} />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </div>
  );
}
