import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import swal from "sweetalert";
import { useMediaQuery } from 'react-responsive'
import Logo_Signature from "../../assets/signature/signatureNewIcon.png"
import { FaBackspace, FaBackward, FaCamera, FaHandHoldingMedical, FaReply, FaReplyAll, FaReplyd, FaSearch, FaUndo, FaUndoAlt } from "react-icons/fa";
import { API } from "../../config/api";
import React, { useRef } from 'react';
import LoaderHome from "../../components/Loader/LoaderHome"
import Swal from "sweetalert2";
import "../../index.css"
import LogoDatePicker from "../../assets/signature/logo-date-pick.png";

export default function Login() {
  let navigate = useNavigate();
  document.title = "Register Pasien";
  const [state, dispatch] = useContext(UserContext);
  const [message, setMessage] = useState(null);
  const [imageName, setImageName] = useState([])
  const [imageContent, setImageContent] = useState([])
  const [preview, setPreview] = useState(null);
  const token = localStorage.getItem("token");
  const nama = localStorage.getItem("username")
  const [isFocused, setIsFocused] = useState(false);
  const [getCode, setGetCode] = useState([]);

  const previewReset = () => {
    setPreview(null)
    setImageContent([])
  }

  let fetchParams = {
    headers : {
      "Authorization" : `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzZGMtYXBwcy1hcGkiLCJzdWIiOiJlZmZjODU4ZS1iMzU1LTQzMjktYjNmYy01NWQ5ODExYzg5ODQiLCJlbWFpbCI6ImR3aWtpX3dhbnRhcmFAZ3JhdGlham0uY28uaWQiLCJpYXQiOjE3MzM0NTg0NzB9.yJRnxqSbMxOUd6tKZ1UQ3Y48wiJYd1pGSRnvQqAJTTA`,
      "Content-Type" : "application/json"
    }
  };
 
  const [form, setForm] = useState({
    firts_name: "",
    last_name: "",
    gender: "",
    day_birth: "",
    month_birth: "",
    year_birth: "",
    date_birth: "",
    address: "",
    nic_number: "",
	  phone_number: "",
    email: "",
    password: "",
    confirmPassword: "",
    medical_record_code : ""
  });

  const {
    firts_name,
    last_name,
    gender,
    date_birth,
    address,
    phone_number,
    email,
    nic_number,
    password,
    confirmPassword,
    medical_record_code
  } = form;

  const generateCode = async () => {
    try {
      setLoading(true);

      const response = await API.post("user-service/master-codes/generate", { type: "emr" }, fetchParams);
  
      if (response?.status === 200) {
        setGetCode(response.data.data.number);
        setForm((prevForm) => ({
          ...prevForm,
          medical_record_code: response.data.data.number,
        }));
        setLoading(false);
      }
      
    } catch (error) {
      setLoading(false);
      console.error("Error in generateCode:", error);
      swal({
        title: "Failed",
        text: `${error.response?.data?.message || error.message}`,
        icon: "error",
        timer: 3000,
        buttons: false,
      });
    }
  };
  
  useEffect(() => {
    generateCode(); 
  }, []); 


  const handleChange = async (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.type === "file" ? e.target.files : e.target.value,
    });

    // Create image url for preview
    if (e.target.type === "file") {
      const file = e.target.files[0]
      // console.log(file)
      const base64 = await convertBase64(file)
      setPreview(base64)
      setImageName(e.target.files[0].name)
      setImageContent(base64.split(',')[1])
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const postUser = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
     // Configuration
     const config = {
      headers: {
        "Content-type": "application/json",
      },
     };
      
    if (form?.confirmPassword === "") {
    setLoading(false)
    return Swal.fire(
      'Confirm Password Harus Di Isi',
      'Mohon Periksa',
      'warning'
    )
  }
  
  if (form?.password !== form?.confirmPassword) {
    setLoading(false)
    return Swal.fire(
      'Password Anda Dengan Confirm Password Berbeda',
      'Mohon Periksa',
      'warning'
    )
  }
    const response = await API.post(`transaction-service/patient-registrations/store`, 
    {
      first_name: firts_name? firts_name : "",
      last_name: last_name? last_name : "",
      gender: gender? gender : "",
      date_birth: date_birth ? date_birth : "",
      phone_number: phone_number ? phone_number : "",
      address: address? address : "",
      email: email? email : "",
      nic_number: nic_number? nic_number : "",
      password: password ,
      residence_address: address? address : "",
      medical_record_code: medical_record_code? medical_record_code : "" 
    }
    , config)
    
    if (response?.status === 200) {
    setLoading(false)
    Swal.fire(
      'Selamat Anda Berhasil Registrasi',
      'Silahkan Login Untuk Membuat Appointment',
      'success'
    )
    navigate("/")
    }
      
    } catch (error) {
    swal({
      title: 'Failed',
      text: `${error.response.data.message}`,
      icon: 'error',
      buttons: false
    });
    setLoading(false)
  }
  }

  const modalViewTreatment = ()=>{
    setModalShow(true)
  }

  const navigateHome = () => {
    if (window.confirm('Apakah kamu yakin mau keluar dari halaman ini?')) {
      // Navigasi ke halaman utama atau tujuan lain
      navigate("/"); // Ini akan mengarahkan pengguna ke halaman sebelumnya
    }
    
  }
 
  const [loading, setLoading] = useState(false);
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)'})
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1000px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const [modalShow, setModalShow] = useState(false);
  const firstNameInputRef = useRef(null);

  // Ketika komponen dimuat, fokus akan diarahkan ke input "Nama Depan"
  React.useEffect(() => {
    firstNameInputRef.current.focus();
  }, []);

  console.log(form)
  return (
    <div style={{ fontFamily: "sans-serif" }}>
      {loading && <LoaderHome />}
    {isTabletOrMobile ?
      <div>
      <div xl='12' sm='12' style={{ backgroundColor:"white"}}>
      <form onSubmit={(e) =>postUser} style={{width:"100%"}}>
        <div style={{ display: "flex", backgroundColor: "white", borderBottom:"1px solid #E6E6E6", padding:"10px 0px"}}>
          <div style={{ flex: "60%", fontSize: "15px", display: "flex", alignItems: "center", justifyContent:"center"}}>
            <div>
              <div style={{fontWeight:"bold", color:"#373737"}}>
                Pendaftaran Pasien Baru
              </div>
              <div style={{fontSize:"12px", color:"#979797"}}>
                PT. Signature Anugerah Sentosa
              </div>
            </div>
          </div> 
              
          <div style={{ flex: "40%"}}>
            <div style={{display:"flex", justifyContent:"center"}}>
              <img src={Logo_Signature} alt="logo-signature" style={{ display: "flex", width: "100px"}}></img>
            </div>
          
        </div> 
        </div>
    
      <div style={{ backgroundColor: "white", borderBottom:"5px solid #E1E1E1"}}>
      <div style={{ padding: "10px 15px" }}>
                    
        <div className="mt-2" style={{ display: "flex"}}>
          <div style={{ width: "100%" }}>
            <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Nama Depan</div>
                <input  className="focused" type="text" placeholder='Masukan nama depan anda'  name="firts_name" ref={firstNameInputRef} value={firts_name} onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", borderRadius:"5px", paddingLeft:"5px", height:"7vh"}}/>
              {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Email Wajib Aktif</div> */}
            </div>             
                    </div>
                    
                    <div className="mt-4" style={{ display: "flex"}}>
          <div style={{ width: "100%" }}>
            <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Nama Belakang</div>
                <input  className="focused" type="text" placeholder='Masukan nama belakang anda'  name="last_name" value={last_name} onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", borderRadius:"5px", paddingLeft:"5px", height:"7vh"}}/>
              {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Email Wajib Aktif</div> */}
            </div>             
                    </div>
                    
                    <div className="mt-4" style={{ display: "flex"}}>
                    <div style={{ width: "100%" }}>
                <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Jenis Kelamin</div>
                <select className="form-select focused" aria-label="Default select example" onChange={handleChange}  name="gender" style={{ textAlign:"", cursor:"pointer", height:"7vh"}}>
                <option value="" hidden>Pilih</option>
                <option value="Laki-Laki" style={{textAlign:""}}>Laki-Laki</option>
                <option value="Wanita" style={{textAlign:""}}>Wanita</option>              
              </select>                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
            </div>            
                    </div>
                    
                    <div className="mt-4" style={{ display: "flex"}}>
                    <div style={{ width: "100%" }}>
                <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Tanggal Lahir</div>
                    <input  className="focused" type="Date" placeholder='Masukan Nama Belakang Anda'  name="date_birth" onChange={handleChange}  style={{
                    width: "100%",
                    border: "1px solid #C9C9C9",
                    outline: "",
                    height: "7vh",
                    borderRadius: "5px",
                    paddingLeft: "5px",
                    backgroundImage: `url(${LogoDatePicker})`,
                    backgroundPosition: 'calc(100% - 10px) center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '25px',
                    }}/>
                  {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
                </div>            
                    </div>
                    
                    <div className="mt-4" style={{ display: "flex"}}>
                    <div style={{ width: "100%" }}>
                <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Nomor Handphone</div>
                    <input  className="focused" type="number" placeholder='Masukan nomor handphone anda' value={phone_number} name="phone_number" onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"",  height:"7vh", borderRadius:"5px", paddingLeft:"5px", appearance:"textfield"}}/>
                  {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
                </div>          
                    </div>
                    
                    <div className="mt-4" style={{ display: "flex"}}>
                    <div style={{ width: "100%" }}>
                <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Alamat</div>
                    <input  className="focused" type="text" placeholder='Masukan alamat anda' value={address} name="address" onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", height:"7vh", borderRadius:"5px", paddingLeft:"5px"}}/>
                  {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
                </div>             
                    </div>
                    
                    <div className="mt-4" style={{ display: "flex"}}>
          <div style={{ width: "100%" }}>
            <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Email</div>
                <input  className="focused" type="email" placeholder='@gmail.com'  name="email" value={email} onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", height:"7vh", borderRadius:"5px", paddingLeft:"5px"}}/>
              <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Email Wajib Aktif</div>
            </div>             
                    </div>
                    
                  

                    <div className="mt-4" style={{ display: "flex"}}>
                    <div style={{ width: "100%" }}>
                <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Password</div>
                    <input  className="focused" type="password" placeholder='***********'  name="password" value={password} onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", height:"7vh", borderRadius:"5px", paddingLeft:"5px"}}/>
                  {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
                </div>          
                    </div>
                    
                    <div className="mt-4" style={{ display: "flex"}}>
                    <div style={{ width: "100%" }}>
                <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Confirm Passsword</div>
                    <input  className="focused" type="password" placeholder='***********'  name="confirmPassword" value={confirmPassword} onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", height:"7vh", borderRadius:"5px", paddingLeft:"5px"}}/>
                  {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
                </div>            
        </div>
        <div className="mt-4" style={{ display: "flex", justifyContent: "end", padding: "20px 0px 20px 0px" }}>
          <div className="mr-4" style={{width:"200px"}}>
            <Button  onClick={navigateHome}  color='secondary' block style={{ fontSize:"15px", borderRadius:"3px"}}>
              Kembali
            </Button>
          </div>
          <div style={{width:"200px"}}>
            <Button onClick={postUser} type='submit' color='primary' block style={{ fontSize:"15px", borderRadius:"3px"}}>
              Daftar
            </Button>
          </div>
        </div>
                    
        </div>
      </div>
    
          </form>
        </div>
      </div>
        :
      <Row>
        <Col xl='12' sm='12' style={{ backgroundColor:"#ECF1FF", padding: "50px 350px 50px 350px", fontFamily:"lato" }}>
          <form onSubmit={(e) =>postUser} style={{width:"100%"}}>
          <div style={{ display: "flex", backgroundColor: "white", borderBottom:"1px solid #E6E6E6", padding:"20px 0px"}}>
            <div style={{ flex: "60%", fontSize: "25px", display: "flex", alignItems: "center", justifyContent:"center"}}>
              <div>
                <div style={{fontWeight:"bold", color:"#373737"}}>
                  Pendaftaran Pasien Baru
                </div>
                <div style={{fontSize:"12px", color:"#979797"}}>
                  PT. Signature Anugerah Sentosa
                </div>
              </div>
            </div> 
                
            <div style={{ flex: "40%"}}>
              <div style={{display:"flex", justifyContent:"center"}}>
                <img src={Logo_Signature} alt="logo-signature" style={{ display: "flex", width: "100px"}}></img>
              </div>
            
            </div> 
              </div>
              
        
          <div style={{ backgroundColor: "white", borderBottom:"5px solid #E1E1E1"}}>
            <div style={{ padding: "10px 30px" }}>
              
            <div className="mt-3" style={{ display: "flex" }}>
              
                  </div> 
                  
        <div className="" style={{ display: "flex"}}>
          <div style={{flex:"50%", display:"flex",alignItems:"center", marginRight:"20px"}}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Nama Depan</div>
                  <input className="focused" type="text" placeholder='Masukan nama depan anda' value={firts_name} name="firts_name" ref={firstNameInputRef} onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>
                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
              </div>
              </div>
              <div style={{flex:"50%", display:"flex",alignItems:"center"}}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Nama Belakang</div>
                  <input className="focused" type="text" placeholder='Masukan nama belakang anda' value={last_name} name="last_name" onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>
                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
              </div>
          </div>
         
        </div>
                  
        <div className="mt-4" style={{ display: "flex"}}>
          <div style={{flex:"50%", display:"flex",alignItems:"center", marginRight:"20px"}}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Jenis Kelamin</div>
              <select className="form-select focused" aria-label="Default select example" onChange={handleChange}  name="gender" style={{ textAlign:"", cursor:"pointer"}}>
              <option value="" hidden>Pilih</option>
              <option value="Laki-Laki" style={{textAlign:""}}>Laki-Laki</option>
              <option value="Wanita" style={{textAlign:""}}>Wanita</option>              
            </select>                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
          </div>
          </div>
          <div style={{flex:"50%", display:"flex",alignItems:"center"}}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Tanggal Lahir</div>
                <input className="focused" type="Date" placeholder='Masukan Nama Belakang Anda' name="date_birth" onChange={handleChange}
                  style={{
                  width: "100%",
                  border: "1px solid #C9C9C9",
                  outline: "",
                  height: "37.5px",
                  borderRadius: "5px",
                  paddingLeft: "5px",
                  backgroundImage: `url(${LogoDatePicker})`,
                  backgroundPosition: 'calc(100% - 10px) center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '25px',
                  }}/>
                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
              </div>
          </div>
        </div>
                  

        <div className="mt-4" style={{ display: "flex"}}>
          <div style={{flex:"50%", display:"flex",alignItems:"center", marginRight:"20px"}}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Nomor Handphone</div>
                  <input  className="focused" type="number" placeholder='Masukan nomor handphone anda' value={phone_number} name="phone_number" onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", height:"35px", borderRadius:"5px", paddingLeft:"5px", appearance:"textfield"}}/>
                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
              </div>
              </div>
              <div style={{flex:"50%", display:"flex",alignItems:"center"}}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Alamat</div>
                  <input  className="focused" type="text" placeholder='Masukan alamat anda' value={address} name="address" onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>
                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
              </div>
          </div>
          </div>
        <div className="mt-4" style={{ display: "flex"}}>
          <div style={{flex:"50%", display:"flex",alignItems:"center", marginRight:"20px"}}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>NIK KTP</div>
                  <input  className="focused" type="number" placeholder='Masukan nomor ktp anda' value={nic_number} name="nic_number" onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", height:"35px", borderRadius:"5px", paddingLeft:"5px", appearance:"textfield"}}/>
                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
              </div>
              </div>
              <div style={{flex:"50%", display:"flex",alignItems:"center"}}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Email</div>
              <input  className="focused" type="email" placeholder='@gmail.com'  name="email" value={email} onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>
                  
              </div>
          </div>
          </div>
          <div className="mt-4" style={{ display: "flex" }}>
            <div style={{ width: "100%" }}>
              <div style={{ fontSize: "12px", fontWeight: "bold" }}>Nomor Rekam Medis</div>
              <input
                className="focused"
                type="text"
                placeholder="Nomor rekam medis..."
                name="medical_record_code"
                value={`${getCode}`} 
                onChange={handleChange} 
                style={{
                  width: "100%",
                  border: "1px solid #C9C9C9",
                  height: "7vh",
                  borderRadius: "5px",
                  paddingLeft: "5px",
                }}
              />
            </div>
          </div>
                  
        <div className="mt-4" style={{ display: "flex"}}>
          <div style={{flex:"50%", display:"flex",alignItems:"center", marginRight:"20px"}}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Password</div>
                  <input  className="focused" type="password" placeholder='***********'  name="password" value={password} onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>
                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
              </div>
              </div>
              <div style={{flex:"50%", display:"flex",alignItems:"center"}}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Konfirmasi Passsword</div>
                  <input  className="focused" type="password" placeholder='***********'  name="confirmPassword" value={confirmPassword} onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>
                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
              </div>
          </div>   
      </div>
            
      <div className="mt-4" style={{ display: "flex", justifyContent: "center", padding: "20px 0px 20px 0px" }}>
        <div className="mr-4" style={{width:"200px"}}>
          <Button onClick={navigateHome}  color='secondary' block style={{ fontSize:"15px", borderRadius:"3px"}}>
            Kembali
          </Button>
        </div>
        <div style={{width:"200px"}}>
          <Button onClick={postUser} type='submit' color='primary' block style={{ fontSize:"15px", borderRadius:"3px"}}>
            Daftar
          </Button>
        </div>
      </div>
                        
        </div>
      </div>
        
      </form>
            
        </Col>
      </Row>
      }
    </div>
  );
}
