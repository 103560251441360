/* eslint-disable react-hooks/exhaustive-deps */
import {  useEffect, useState } from "react";
import { Form,Button } from 'reactstrap'
import { API } from "../../../config/api";
import {FaTimes} from 'react-icons/fa'
import Modal from 'react-bootstrap/Modal';
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import "../Styled.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import LoaderHome from "../../Loader/LoaderHome"
import axios from "axios";
// generate s

export default function ModalAdminUpdate(props) {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  const [getDataCode, setGetCode] = useState()
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending] = useState(0);
  const [getProvince, setGetProvince] = useState([])
  const [getRegency, setGetRegency] = useState([])
  const [getDistrict, setGetDistrict] = useState([])
  const [getSubDistrict, setGetSubDistrict] = useState([])
  const [provinceLoaded, setProvinceLoaded] = useState(false);
  const [cityLoaded, setCityLoaded] = useState(false);
  const [districtLoaded, setDistrictLoaded] = useState(false);
  const [getDataCoba, setGetDataCoba] = useState([])
  const [getDataTokenSatuSehat , setGetDataTokenSatuSehat] = useState([]);

  const [errors, setErrors] = useState({});


  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };
  


  const [form, setForm] = useState({
    // data awal
    first_name: "",
    last_name: "",
    residence_address: "",
    phone_number: "",
    day_birth: "",
    month_birth: "",
    year_birth: "",
    gender: "",
    // data update
    medical_record_code: "",
    nic_number: "",
    nic_address:"",
    status: "",
    education: "",
    occupation: "",
    nationality: "",

    // wilayah
    regencies: "",
    districts: "",
    sub_district: "",

    token_satu_sehat: "",
  });
  


  const GenerateTokenSatuSehat = async () => {
    try {
      setLoading(true);
      let fetchParamsTokenSatuSehat = {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      };

      const data = new URLSearchParams();
      data.append('grant_type', 'client_credentials');
      data.append('client_id', `nDxgNY0hDagedMV46uqxej2CedYNsQYsIBC84jlJvQnbCAlA`);
      data.append('client_secret', `DxQDxoszRwbZOwod6A46rANTrGYifer3Y3JMD0zxmUTezwEAAyY9Wnz6Rzjdkboj`);
  
      const response = await axios.post(`https://api-satusehat.kemkes.go.id/oauth2/v1/accesstoken?grant_type=client_credentials`, data, fetchParamsTokenSatuSehat);

      if (response?.status === 200) {
        setGetDataTokenSatuSehat(response.data);
        setLoading(false);
      }
      
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        showConfirmButton: false
      });
    }
  };

  useEffect(() => {
      if(getDataTokenSatuSehat.length === 0){
        GenerateTokenSatuSehat()
      }
    }, [getDataTokenSatuSehat])

  const generateCode = async () => {
    const response = await API.post(`user-service/master-codes/generate`,
    {
      type: "emr",
    },
    fetchParams)
    setGetCode(response.data.data)
    if (response?.data.error) {
      swal({
        title: 'Failed',
        text: response.data.message,
        icon: 'error',
        timer: 3000,
        buttons: false
      })
    };
  }

  useEffect(() => {
    generateCode()
  }, [])

  useEffect(() => {
    if (getDataCode) {
      setForm({
        ...form,
       medical_record_code: getDataCode?.number
      });
  }
  }, [getDataCode])

  console.log(form)
  

  // const handleChange = (e) => {
  //   setForm({
  //     ...form,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => {
      const updatedForm = { ...prevForm, [name]: value };
  
      // Reset hanya jika terjadi perubahan nilai
      if (name === "nic_address" && prevForm.nic_address !== value) {
        updatedForm.regencies = "";
        updatedForm.districts = "";
        updatedForm.sub_district = "";
      }
      if (name === "regencies" && prevForm.regencies !== value) {
        updatedForm.districts = "";
        updatedForm.sub_district = "";
      }
      if (name === "districts" && prevForm.districts !== value) {
        updatedForm.sub_district = "";
      }
  
      return updatedForm;
    });
  };
  

  useEffect(() => {
    const fetchDataProvince = async () => {
      try {
        setLoading(true);
        const response = await API.get(`user-service/masterdata/v1/provinces?limit=1000`, fetchParams);
        setGetProvince(response.data.data.data);

        if (response?.status === 200) {
          setProvinceLoaded(true); // Tandai bahwa provinces sudah selesai di-load
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Swal.fire({
          title: "Failed",
          text: `${error.response?.data?.message || "Error fetching provinces"}`,
          timer: 3000,
          showConfirmButton: false,
        });
      }
    };

    fetchDataProvince();
  }, []);

  // Fetch cities (dijalankan setelah provinces selesai di-load)
  useEffect(() => {
    if (provinceLoaded && form.nic_address) {
      const fetchDataRegencies = async () => {
        try {
          setLoading(true);
          const response = await API.get(
            `user-service/masterdata/v1/cities/${form.nic_address}?limit=1000`,
            fetchParams
          );
          setGetRegency(response.data.data.data);

          if (response?.status === 200) {
            setCityLoaded(true); // Tandai bahwa cities sudah selesai di-load
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          Swal.fire({
            title: "Failed",
            text: `${error.response?.data?.message || "Error fetching cities"}`,
            timer: 3000,
            showConfirmButton: false,
          });
        }
      };

      fetchDataRegencies();
    }
  }, [provinceLoaded, form.nic_address]);

  // Fetch districts (dijalankan setelah cities selesai di-load)
  useEffect(() => {
    if (cityLoaded && form.regencies) {
      const fetchDataDistricts = async () => {
        try {
          setLoading(true);
          const response = await API.get(
            `user-service/masterdata/v1/regencies/${form.regencies}?limit=1000`,
            fetchParams
          );
          setGetDistrict(response.data.data.data);

          if (response?.status === 200) {
            setDistrictLoaded(true); // Tandai bahwa districts sudah selesai di-load
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          Swal.fire({
            title: "Failed",
            text: `${error.response?.data?.message || "Error fetching districts"}`,
            timer: 3000,
            showConfirmButton: false,
          });
        }
      };

      fetchDataDistricts();
    }
  }, [cityLoaded, form.regencies]);

  // Fetch sub-districts (dijalankan setelah districts selesai di-load)
  useEffect(() => {
    if (districtLoaded && form.districts) {
      const fetchDataSubDistricts = async () => {
        try {
          setLoading(true);
          const response = await API.get(
            `user-service/masterdata/v1/districts/${form.districts}?limit=1000`,
            fetchParams
          );
          setGetSubDistrict(response.data.data.data);

          setLoading(false);
        } catch (error) {
          setLoading(false);
          Swal.fire({
            title: "Failed",
            text: `${error.response?.data?.message || "Error fetching sub-districts"}`,
            timer: 3000,
            showConfirmButton: false,
          });
        }
      };

      fetchDataSubDistricts();
    }
  }, [districtLoaded, form.districts]);

  

  const handleSubmit = useMutation(async (e) => {
    try {
      setLoading(true)
      e.preventDefault();
      const response = await API.post(`transaction-service/patient-registrations/store`, {
        first_name: form?.first_name,
        last_name: form?.last_name,
        gender: form?.gender,
        residence_address: form?.residence_address,
        phone_number: form?.phone_number,
        date_birth: form?.year_birth && form?.month_birth && form?.day_birth ? `${form?.year_birth}-${form?.month_birth}-${form?.day_birth}` : "",
        medical_record_code: form?.medical_record_code,
        nic_number: form?.nic_number,
        // nic_address: form?.nic_address,
        province: form?.nic_address,
        regency: form?.regencies,
        district: form?.districts,
        sub_district: form?.sub_district,
        status: form?.status,
        education: form?.education,
        occupation: form?.occupation,
        nationality: form?.nationality
      }, fetchParams);

      if (response?.status === 200) {
        setLoading(false)
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        });
        props.GetResponseData();
        props.onHide();
        setLoading(false);
      }

    } catch (error) {
        setLoading(false)
      if (error.response && error.response.data.message) {
        // Simpan error berdasarkan field
        setErrors(error.response.data.message);        
        swal({
          title: 'Validation Error',
          text: "Harap Lengkapi data yang penting untuk diisi!",
          icon: 'error',
          timer: 5000,
          buttons: false
        });
      } else {
        swal({
          title: 'Failed',
          text: error.response?.data?.message || 'Terjadi kesalahan',
          icon: 'error',
          timer: 3000,
          buttons: false
        });
      }
    }
  });

     
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{fontFamily:"sans-serif",border:"none"}}>
      {loading && <LoaderHome />}
      <div style={{ width: "100%", display: "flex", padding: "10px 0px", backgroundColor: "#29B8FF" }}>
        <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", paddingLeft:"10px", color:"white", fontWeight:"600"}}>
          Tambah Pasien 
        </div>  
        <div  style={{flex:"8%",fontSize:"30px",display:"flex",alignItems:"center",justifyContent:"center", color:"white"}}>
          <FaTimes onClick={() => setProopsData(props.onHide)} style={{cursor:"pointer"}}/>
        </div> 
      </div>
      <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px",border:"none" }}>
      <div>
          <div>
            <Form onSubmit={(e) => handleSubmit.mutate(e)} className="mt-3" >
              <div className="mt-2" style={{ display: "flex" }}>
                {/* Label untuk Nama Depan */}
                <div style={{ flex: "5%", display: "flex", alignItems: "center" }}>
                  <span>Nama Depan</span>
                  <div style={{ color: "red", fontSize: "18px" }}>*</div>
                </div>
                {/* Input dan Error */}
                <div style={{ flex: "60%", display: "flex" }}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
                  <div style={{ width: "100%", outline: "none", padding: "10px 0px" }}>
                    <input
                      autoFocus
                      type="text"
                      value={form.first_name}
                      name="first_name"
                      onChange={handleChange}
                      style={{
                        width: "100%",
                        height: "100%",
                        paddingLeft: "0px",
                        borderTop: "none",
                        borderRight: "none",
                        borderLeft: "none",
                        borderBottom: `1px solid ${errors.first_name ? 'red' : '#B6B6B6'}`,
                        outline: "none"
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* Pesan error spesifik untuk field ini */}
              {errors.first_name && (
                <p className="help is-danger" style={{ fontSize: "12px", marginLeft: "24%" }}>
                  {errors.first_name}
                </p>
              )}


              <div className="mt-2" style={{ display: "flex" }}>
                {/* Label untuk Nama Belakang */}
                <div style={{ flex: "5%", display: "flex", alignItems: "center" }}>
                  <span>Nama Belakang</span>
                </div>
                {/* Input dan Error */}
                <div style={{ flex: "60%", display: "flex" }}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
                  <div style={{ width: "100%", outline: "none", padding: "10px 0px" }}>
                    <input
                      autoFocus
                      type="text"
                      value={form.last_name}
                      name="last_name"
                      onChange={handleChange}
                      style={{
                        width: "100%",
                        height: "100%",
                        paddingLeft: "0px",
                        borderTop: "none",
                        borderRight: "none",
                        borderLeft: "none",
                        borderBottom: `1px solid ${errors.last_name ? 'red' : '#B6B6B6'}`,
                        outline: "none"
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* Pesan error spesifik untuk field ini */}
              {errors.last_name && (
                <p className="help is-danger" style={{ fontSize: "12px", marginLeft: "24%" }}>
                  {errors.last_name}
                </p>
              )}

              <div className="mt-2" style={{ display: "flex" }}>
                {/* Label untuk Nomor HP */}
                <div style={{ flex: "5%", display: "flex", alignItems: "center" }}>
                  <span>Nomor HP</span>
                  <div style={{ color: "red", fontSize: "18px" }}>*</div>
                </div>
                {/* Input dan Error */}
                <div style={{ flex: "60%", display: "flex" }}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
                  <div style={{ width: "100%", outline: "none", padding: "10px 0px" }}>
                    <input
                      autoFocus
                      type="text"
                      value={form?.phone_number}
                      name="phone_number"
                      onChange={handleChange}
                      style={{
                        width: "100%",
                        height: "100%",
                        paddingLeft: "0px",
                        borderTop: "none",
                        borderRight: "none",
                        borderLeft: "none",
                        borderBottom: `1px solid ${errors.phone_number ? 'red' : '#B6B6B6'}`,
                        outline: "none"
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* Pesan error spesifik untuk field ini */}
              {errors.phone_number && (
                <p className="help is-danger" style={{ fontSize: "12px", marginLeft: "24%" }}>
                  {errors.phone_number}
                </p>
              )}

              <div className="mt-2" style={{ display: "flex" }}>
                {/* Label untuk Alamat */}
                <div style={{ flex: "5%", display: "flex", alignItems: "center" }}>
                  <span>Alamat</span>
                  <div style={{ color: "red", fontSize: "18px" }}>*</div>
                </div>
                {/* Input dan Error */}
                <div style={{ flex: "60%", display: "flex" }}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
                  <div style={{ width: "100%", outline: "none", padding: "10px 0px" }}>
                    <input
                      autoFocus
                      type="text"
                      value={form?.residence_address}
                      name="residence_address"
                      onChange={handleChange}
                      style={{
                        width: "100%",
                        height: "100%",
                        paddingLeft: "0px",
                        borderTop: "none",
                        borderRight: "none",
                        borderLeft: "none",
                        borderBottom: `1px solid ${errors.residence_address ? 'red' : '#B6B6B6'}`,
                        outline: "none"
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* Pesan error spesifik untuk field ini */}
              {errors.residence_address && (
                <p className="help is-danger" style={{ fontSize: "12px", marginLeft: "24%" }}>
                  {errors.residence_address}
                </p>
              )}

              <div className="mt-2 is-flex">
                {/* Label untuk Jenis Kelamin */}
                <div className="is-flex is-align-items-center" style={{ flex: "5%" }}>
                  <span>Jenis Kelamin</span>
                  <span className="has-text-danger" style={{ fontSize: "18px" }}>*</span>
                </div>
                {/* Input dan Error */}
                <div className="is-flex is-flex-direction-column" style={{ flex: "60%" }}>
                  {/* Dropdown */}
                  <div className="field">
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          className={`input ${errors.gender ? 'is-danger' : ''}`}
                          onChange={handleChange}
                          value={form?.gender}
                          name="gender"
                        >
                          <option value="" hidden style={{ color: "#B5B5B5" }}>Pilih</option>
                          <option value="male">Laki-Laki</option>
                          <option value="female">Wanita</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Pesan error spesifik untuk field ini */}
              {errors.gender && (
                <p className="help is-danger" style={{ fontSize: "12px", marginLeft: "24%" }}>
                  {errors.gender}
                </p>
              )}

              <div className="mt-2 is-flex">
                {/* Label untuk Tanggal Lahir */}
                <div className="is-flex is-align-items-center" style={{ flex: "5%" }}>
                  <span>Tanggal Lahir</span>
                  <span className="has-text-danger" style={{ fontSize: "18px" }}>*</span>
                </div>
                {/* Input dan Error */}
                <div className="is-flex is-flex-direction-column" style={{ flex: "60%" }}>
                  {/* Day, Month, Year Dropdowns */}
                  <div className="field is-grouped is-fullwidth">
                    <div className="control is-expanded">
                      <div className={`select is-fullwidth ${errors.date_birth ? 'is-danger' : ''}`}>
                        <select
                          className={`input`}
                          value={form?.day_birth}
                          onChange={handleChange}
                          name="day_birth"
                        >
                          <option value="" hidden>Day</option>
                          {Array.from({ length: 31 }, (_, i) => i + 1).map(day => (
                            <option key={day} value={day.toString().padStart(2, '0')}>{day}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="control is-expanded">
                      <div className={`select is-fullwidth  ${errors.date_birth ? 'is-danger' : ''}`}>
                        <select
                          className={`input`}
                          value={form?.month_birth}
                          onChange={handleChange}
                          name="month_birth"
                        >
                          <option value="" hidden>Month</option>
                          {[
                            "Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", 
                            "Agustus", "September", "Oktober", "November", "Desember"
                          ].map((month, i) => (
                            <option key={i} value={(i + 1).toString().padStart(2, '0')}>{month}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="control is-expanded">
                      <div className={`select is-fullwidth ${errors.date_birth ? 'is-danger' : ''}`}>
                        <select
                          className={`input`}
                          value={form?.year_birth}
                          onChange={handleChange}
                          name="year_birth"
                        >
                          <option value="" hidden>Year</option>
                          {Array.from({ length: 84 }, (_, i) => 1940 + i).map(year => (
                            <option key={year} value={year}>{year}</option>
                          ))}
                        </select>
                      </div>
                    </div>                  
                  </div>
                </div>
              </div>
              {/* Pesan error spesifik untuk field ini */}
              {(errors.date_birth) && (
                <p className="help is-danger" style={{ fontSize: "12px", marginLeft: "24%" }}>
                  {errors.date_birth}
                </p>
              )}

              <div className="mt-2 is-flex">
                {/* Label untuk Nomor Rekam Medis */}
                <div className="is-flex is-align-items-center" style={{ flex: "5%" }}>
                  <span>Nomor Rekam Medis</span>
                </div>
                {/* Input dan Error */}
                <div className="is-flex is-flex-direction-column" style={{ flex: "60%" }}>
                  {/* Input Field */}
                  <div className="field">
                    <div className="control">
                      <input
                        type="text"
                        value={form?.medical_record_code}
                        name="medical_record_code"
                        onChange={handleChange}
                        className={`input ${errors.medical_record_code ? 'is-danger' : ''}`}
                        placeholder="Masukkan nomor rekam medis..."
                      />
                      {/* Pesan error spesifik untuk field ini */}
                      {errors.medical_record_code && (
                        <p className="help is-danger" style={{ fontSize: "12px" }}>
                          {errors.medical_record_code}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="mt-2" style={{ display: "flex"}}>
                <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
                  Nomor KTP <div style={{ color:"red", fontSize: "18px" }}>  * </div> 
                </div>
                <div style={{ flex: "60%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
                  <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
                    <input autoFofcus type='text' value={form?.nic_number} name="nic_number" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none",  borderBottom: `1px solid ${errors.nic_number ? 'red' : '#B6B6B6'}`, outline:"none"}}/>
                  </div>
                </div>
              </div>
              {/* Pesan error spesifik untuk field ini */}
              {errors.nic_number && (
                <p className="help is-danger" style={{ fontSize: "12px", marginLeft: "24%" }}>
                  {errors.nic_number}
                </p>
              )}

              {/* Start */}
                <div className="mt-2" style={{ display: "flex" }}>
                  <div style={{ flex: "5%", display: "flex", alignItems: "center" }}>
                    Alamat KTP
                    <div style={{ color: "red", fontSize: "18px" }}>*</div>
                  </div>
                  <div style={{ flex: "60%", display: "flex" }}>
                    <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
                    <div style={{ width: "100%", outline: "none", padding: "10px 0px" }}>
                      <select
                        name="nic_address" 
                        value={form?.nic_address}
                        onChange={handleChange} 
                        style={{
                          width: "100%",
                          height: "100%",
                          paddingLeft: "0px",
                          borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          borderBottom: `1px solid ${errors.province ? 'red' : '#B6B6B6'}`,
                          outline: "none"
                        }}
                      >
                        <option value="">Pilih Provinsi</option>
                        {getProvince.map((province, index) => (
                          <option key={index} value={province.code}>
                            {province.name} 
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {errors.nic_address && (
                  <p className="help is-danger" style={{ fontSize: "12px", marginLeft: "24%" }}>
                    {errors.nic_address}
                  </p>
                )}

                <div className="mt-2" style={{ display: "flex" }}>
                  <div style={{ flex: "5%", display: "flex", alignItems: "center" }}>
                  </div>
                  <div style={{ flex: "60%", display: "flex" }}>
                    <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
                    <div style={{ width: "100%", outline: "none", padding: "10px 0px" }}>
                      <select
                        name="regencies" 
                        value={form?.regencies} 
                        onChange={handleChange} 
                        disabled={!form?.nic_address}
                        style={{
                          width: "100%",
                          height: "100%",
                          paddingLeft: "0px",
                          borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          borderBottom: `1px solid ${errors.regency ? 'red' : '#B6B6B6'}`,
                          outline: "none",
                        }}
                      >
                        <option value="">Pilih Kabupaten/Kota</option>
                        {getRegency.map((regency) => (
                          <option key={regency.code} value={regency.id}>
                            {regency.name}  
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {errors.regency && (
                  <p className="help is-danger" style={{ fontSize: "12px", marginLeft: "24%" }}>
                    {errors.regency}
                  </p>
                )}

                <div className="mt-2" style={{ display: "flex" }}>
                  <div style={{ flex: "5%", display: "flex", alignItems: "center" }}>
                  </div>
                  <div style={{ flex: "60%", display: "flex" }}>
                    <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
                    <div style={{ width: "100%", outline: "none", padding: "10px 0px" }}>
                      <select
                        name="districts"
                        value={form?.districts} 
                        onChange={handleChange}
                        disabled={!form?.regencies}
                        style={{
                          width: "100%",
                          height: "100%",
                          paddingLeft: "0px",
                          borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          borderBottom: `1px solid ${errors.district ? 'red' : '#B6B6B6'}`,
                          outline: "none"
                        }}
                      >
                        <option value="">Pilih Kecamatan</option>
                        {getDistrict.map((district) => (
                          <option key={district.code} value={district.id}>
                            {district.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {errors.district && (
                  <p className="help is-danger" style={{ fontSize: "12px", marginLeft: "24%" }}>
                    {errors.district}
                  </p>
                )}

                <div className="mt-2" style={{ display: "flex" }}>
                  <div style={{ flex: "5%", display: "flex", alignItems: "center" }}>
                  </div>
                  <div style={{ flex: "60%", display: "flex" }}>
                    <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
                    <div style={{ width: "100%", outline: "none", padding: "10px 0px" }}>
                      <select
                        name="sub_district" 
                        value={form?.sub_district}
                        onChange={handleChange}
                        disabled={!form?.districts}
                        style={{
                          width: "100%",
                          height: "100%",
                          paddingLeft: "0px",
                          borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          borderBottom: `1px solid ${errors.sub_district ? 'red' : '#B6B6B6'}`,
                          outline: "none"
                        }}
                      >
                        <option value="">Pilih Kelurahan/Desa</option>
                        {getSubDistrict.map((subDistrict) => (
                          <option key={subDistrict.code} value={subDistrict.id}>
                            {subDistrict.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {errors.sub_district && (
                  <p className="help is-danger" style={{ fontSize: "12px", marginLeft: "24%" }}>
                    {errors.sub_district}
                  </p>
                )}
              {/* End */}
              {/* 
              <div className="mt-2" style={{ display: "flex" }}>
                <div style={{ flex: "5%", display: "flex", alignItems: "center" }}>
                  Alamat KTP
                  <div style={{ color: "red", fontSize: "18px" }}>*</div>
                </div>
                <div style={{ flex: "60%", display: "flex" }}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
                  <div style={{ width: "100%", outline: "none", padding: "10px 0px" }}>
                    <input
                      autoFocus
                      type="text"
                      value={form?.nic_address}
                      name="nic_address"
                      onChange={handleChange}
                      style={{
                        width: "100%",
                        height: "100%",
                        paddingLeft: "0px",
                        borderTop: "none",
                        borderRight: "none",
                        borderLeft: "none",
                        borderBottom: `1px solid ${errors.nic_address ? 'red' : '#B6B6B6'}`, // Corrected for the specific field error
                        outline: "none"
                      }}
                    />
                  </div>
                </div>
              </div>
              {errors.nic_address && (
                <p className="help is-danger" style={{ fontSize: "12px", marginLeft: "24%" }}>
                  {errors.nic_address}
                </p>
              )} */}

              <div className="mt-2" style={{ display: "flex"}}>
                <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
                  Status
                </div>
                <div style={{ flex: "60%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
                  <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
                    <input autoFofcus type='text' value={form?.status} name="status" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
                  </div>
                </div>
              </div>

              <div className="mt-2" style={{ display: "flex"}}>
                <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
                  Pendidikan
                </div>
                <div style={{ flex: "60%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
                  <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
                    <input autoFofcus type='text' value={form?.education} name="education" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
                  </div>
                </div>
              </div>

              <div className="mt-2" style={{ display: "flex"}}>
                <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
                  Pekerjaan
                </div>
                <div style={{ flex: "60%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
                  <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
                    <input autoFofcus type='text' value={form?.occupation} name="occupation" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
                  </div>
                </div>
              </div>

              <div className="mt-2" style={{ display: "flex"}}>
                <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
                  Berkebangsaan
                </div>
                <div style={{ flex: "60%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
                  <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
                    <input autoFofcus type='text' value={form?.nationality} name="nationality" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
                  </div>
                </div>
              </div>
              <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"end" }}>
                <div>
                  <Button className="mt-4" type='submit' color='primary' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
                    Tambah
                  </Button>
                </div>
              </div>
            </Form>
          </div>
      </div>
      
        
      </Modal.Body>
      {/* <Modal.Footer>
        <div style={{ display: "flex" }}>
          <Button onClick={props.onHide} style={{marginRight:"8px"}}>Close</Button>
        </div>
      </Modal.Footer> */}
    </Modal>
    );
}
  