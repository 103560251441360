import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaClosedCaptioning, FaTimes} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signatureNewIcon.png"
import Logo_Batik from "../../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import "../Styled.css"
import Swal from "sweetalert2";
import LoaderHome from "../../Loader/LoaderHome"
import swal from "sweetalert";

export default function ModalRoleAdd(props) {
  console.log(props)
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const token = localStorage.getItem("token");
  const [getDataDokter, setGetDataDokter] = useState([]);
  // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  const [loading, setLoading] = useState(false);

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const [form, setForm] = useState({
    height: "",
    weight: "",
    blood_type: "",
    body_temperature: "",
    blood_pressure1: "",
    blood_pressure2: "",
    med_allergy: "",
    med_consuming: "",
    surgery_history: "",
    cardiac_disease: "",
    hemophillia: "",
    diabetes: "",
    hepatitis: "",
    gastritis: "",
    special_condition: ""
  });

  const {
    height,
    weight,
    blood_type,
    body_temperature,
    blood_pressure1,
    blood_pressure2,
    med_allergy,
    med_consuming,
    surgery_history,
    cardiac_disease,
    hemophillia,
    diabetes,
    hepatitis,
    gastritis,
    special_condition
  } = form;

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = useMutation(async (e) => {
    try {
      e.preventDefault();
      setLoading(true)
    
      // Insert data for login process
      const response = await API.post("emr-service/phy-checks/store", {
        id_patient: `${props.idPatientPhysicalCheckPatientAfter}`,
        id_appointment: `${props.idAppoinmentPhysicalCheckAfter}`,
        height: form?.height,
        weight: form?.weight,
        blood_type: form?.blood_type,
        body_temperature: form?.body_temperature,
        blood_pressure: `${form?.blood_pressure1}/${form?.blood_pressure2}`,
        med_allergy: form?.med_allergy,
        med_consuming: form?.med_consuming,
        surgery_history: form?.surgery_history,
        cardiac_disease: form?.cardiac_disease,
        hemophillia: form?.hemophillia,
        diabetes: form?.diabetes,
        hepatitis: form?.hepatitis,
        gastritis: form?.gastritis,
        special_condition: form?.special_condition
      }, fetchParams);
  
      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 2000,
          buttons: false
        })
        props.GetResponseData()
        props.onHide()
        setLoading(false)
      }

    } catch (error) {
      // setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 2000,
        buttons: false
      });
    }
   
  });

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{fontFamily:"sans-serif",border:"none"}}>
      {loading && <LoaderHome />}
      
      <div style={{ width: "100%", display: "flex", padding: "10px 0px", backgroundColor: "#29B8FF" }}>
        <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", paddingLeft:"10px", color:"white", fontWeight:"600"}}>
          Pemeriksaan Pasien
        </div> 
        <div  style={{flex:"8%",fontSize:"30px",display:"flex",alignItems:"center",justifyContent:"center", color:"white"}}>
          <FaTimes onClick={() => setProopsData(props.onHide)} style={{cursor:"pointer"}}/>
        </div> 
      </div>
      <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px",border:"none" }}>
     
      <Form onSubmit={(e) => handleSubmit.mutate(e)} className="mt-3" >
          
          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Tinggi Badan
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ display: "flex", width: "100%", outline: "none", padding: "10px 0px" }}>
                <div style={{flex:"95%", marginRight:"10px"}}>
                  <input autoFofcus type='number' value={height} name="height" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
                </div>
                <div style={{ flex: "5%" }}>
                  cm
                </div>
            </div>
          </div>
          </div>
          
          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Berat Badan
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ display: "flex", width: "100%", outline: "none", padding: "10px 0px" }}>
                <div style={{flex:"95%", marginRight:"10px"}}>
                  <input autoFofcus type='number' value={weight} name="weight" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
                </div>
                <div style={{ flex: "5%" }}>
                  kg
                </div>
            </div>
          </div>
          </div>
          
          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Golongan Darah
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <select className="form-select" aria-label="Default select example" onChange={handleChange}  name="blood_type" style={{ textAlign:"", cursor:"pointer"}}>
              <option value="" hidden>Select</option>
              <option value="A" style={{textAlign:""}}>A</option>
              <option value="A+" style={{textAlign:""}}>A+</option>
              <option value="A-" style={{textAlign:""}}>A-</option>
              <option value="B" style={{textAlign:""}}>B</option>
              <option value="B+" style={{textAlign:""}}>B+</option>
              <option value="B-" style={{textAlign:""}}>B-</option>
              <option value="AB" style={{textAlign:""}}>AB</option>
              <option value="AB+" style={{textAlign:""}}>AB+</option>
              <option value="AB-" style={{textAlign:""}}>AB-</option>
              <option value="O" style={{textAlign:""}}>O</option>
              <option value="O+" style={{textAlign:""}}>O+</option>
              <option value="O-" style={{textAlign:""}}>O-</option>
            </select>
          </div>
          </div>
          
          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Suhu Tubuh
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ display: "flex", width: "100%", outline: "none", padding: "10px 0px" }}>
                <div style={{flex:"95%", marginRight:"10px"}}>
                  <input autoFofcus type='number' step="0.01" value={body_temperature} name="body_temperature" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
                </div>
                <div style={{ flex: "5%" }}>
                &deg;c
                </div>
            </div>
          </div>
          </div>
          
          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Tekanan Darah
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
              <div style={{ display: "flex", width: "100%", outline: "none", padding: "10px 0px" }}>
                <div style={{flex:"50%", marginRight:"10px"}} className="mt-4">
                  <div style={{ display: "flex", gap:"4px" }}>
                    <input autoFofcus type='number' value={blood_pressure1} name="blood_pressure1" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
                    mmHg
                  </div>
                  <div style={{fontSize:"11px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Atas</div>
                </div>
                <div style={{display:"flex",alignItems:"end", height:"100%", fontSize:"20px"}}>/</div>
                <div style={{flex:"50%",marginLeft:"10px"}}>
                  <input autoFofcus type='number' value={blood_pressure2} name="blood_pressure2" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
                  <div style={{fontSize:"11px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Bawah</div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="mt-2" style={{ display: "flex"}}>
            <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
              Alergi
            </div>
            <div style={{ flex: "60%", display:"flex"}}>
              <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
              <div style={{ display: "flex", width: "100%", outline: "none", padding: "10px 0px" }}>
                <input autoFofcus type='text' value={med_allergy} name="med_allergy" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
              </div>
            </div>
          </div>

          <div className="mt-2" style={{ display: "flex"}}>
            <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
              Konsumsi Obat
            </div>
            <div style={{ flex: "60%", display:"flex"}}>
              <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
              <div style={{ display: "flex", width: "100%", outline: "none", padding: "10px 0px" }}>
                <input autoFofcus type='text' value={med_consuming} name="med_consuming" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
              </div>
            </div>
          </div>

          <div className="mt-2" style={{ display: "flex"}}>
            <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
              Riwayat Operasi
            </div>
            <div style={{ flex: "60%", display:"flex"}}>
              <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
              <div style={{ display: "flex", width: "100%", outline: "none", padding: "10px 0px" }}>
                <input autoFofcus type='text' value={surgery_history} name="surgery_history" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
              </div>
            </div>
          </div>

          <div className="mt-2" style={{ display: "flex"}}>
            <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
              Penyakit Jantung
            </div>
            <div style={{ flex: "60%", display:"flex"}}>
              <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
              <div style={{ display: "flex", width: "100%", outline: "none", padding: "10px 0px" }}>
                <input autoFofcus type='text' value={cardiac_disease} name="cardiac_disease" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
              </div>
            </div>
          </div>

          <div className="mt-2" style={{ display: "flex"}}>
            <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
              Hemophilia
            </div>
            <div style={{ flex: "60%", display:"flex"}}>
              <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
              <div style={{ display: "flex", width: "100%", outline: "none", padding: "10px 0px" }}>
                <input autoFofcus type='text' value={hemophillia} name="hemophillia" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
              </div>
            </div>
          </div>

          <div className="mt-2" style={{ display: "flex"}}>
            <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
              Diabetes
            </div>
            <div style={{ flex: "60%", display:"flex"}}>
              <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
              <div style={{ display: "flex", width: "100%", outline: "none", padding: "10px 0px" }}>
                <input autoFofcus type='text' value={diabetes} name="diabetes" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
              </div>
            </div>
          </div>

          <div className="mt-2" style={{ display: "flex"}}>
            <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
              Hepatitis
            </div>
            <div style={{ flex: "60%", display:"flex"}}>
              <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
              <div style={{ display: "flex", width: "100%", outline: "none", padding: "10px 0px" }}>
                <input autoFofcus type='text' value={hepatitis} name="hepatitis" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
              </div>
            </div>
          </div>

          <div className="mt-2" style={{ display: "flex"}}>
            <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
              Gastritis
            </div>
            <div style={{ flex: "60%", display:"flex"}}>
              <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
              <div style={{ display: "flex", width: "100%", outline: "none", padding: "10px 0px" }}>
                <input autoFofcus type='text' value={gastritis} name="gastritis" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
              </div>
            </div>
          </div>

          <div className="mt-2" style={{ display: "flex"}}>
            <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
              Kondisi Khusus
            </div>
            <div style={{ flex: "60%", display:"flex"}}>
              <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
              <div style={{ display: "flex", width: "100%", outline: "none", padding: "10px 0px" }}>
                <input autoFofcus type='text' value={special_condition} name="special_condition" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
              </div>
            </div>
          </div>

          <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"end" }}>
            <div>
              <Button className="mt-4" type='submit' color='primary' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
                Tambahkan
              </Button>
            </div>
          </div>
        </Form>
        
      </Modal.Body>
      {/* <Modal.Footer>
        <div style={{ display: "flex" }}>
          <Button onClick={props.onHide} style={{marginRight:"8px"}}>Close</Button>
        </div>
      </Modal.Footer> */}
    </Modal>
    );
}
  