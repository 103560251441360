/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaClosedCaptioning, FaTimes} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signatureNewIcon.png"
import Logo_Batik from "../../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import "../Styled.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import LoaderHome from "../../Loader/LoaderHome"
// generate

export default function ModalAdminUpdate(props) {
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const token = localStorage.getItem("token");
  const [getDataUserAwal, setGetDataUserAwal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getData, setGetData] = useState([]);

  const [getProvince, setGetProvince] = useState([])
  const [getRegency, setGetRegency] = useState([])
  const [getSubDistrict, setGetSubDistrict] = useState([])
  const [getDistrict, setGetDistrict] = useState([])

  const [selectedProvince, setSelectedProvince] = useState(getDataUserAwal?.province);
  const [selectedRegency, setSelectedRegency] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedSubDistrict, setSelectedSubDistrict] = useState("")

  const [provinceLoaded, setProvinceLoaded] = useState(false);
  const [cityLoaded, setCityLoaded] = useState(false);
  const [districtLoaded, setDistrictLoaded] = useState(false);
  
  // update region
  const [putSelectedRegency, setPutSelectedRegency] = useState("")
  const [putSelectedDistrict, setPutSelectedDistrict] = useState("")
  const [putSelectedSubDistrict, setPutSelectedSubDistrict] = useState("")
  
  const [getDataPatientSsp, setGetDataPatientSsp] = useState([]);
  
  const [getDataTokenSatuSehat , setGetDataTokenSatuSehat] = useState([]);

  // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  
  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  
  const GetResponseDataUsers = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`transaction-service/patients/${props.idUpdatePatient}`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataUserAwal(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 2000,
        buttons: false
      });
    }
  }

  // useEffect(() => {
  //   const GetResponseDataPatientSSP = async () => {
  //     if (!getDataUserAwal?.id) {
  //       return;  
  //     }
  
  //     try {
  //       const response = await API.get(
  //         `user-service/appointment-ssp/${getDataUserAwal?.id}`,
  //         fetchParams
  //       );
        
  //       if (response?.status === 200) {
  //         setGetDataPatientSsp(response.data.data);
  //         setLoading(false);
  //       }
  //     } catch (error) {
  //       setLoading(false);
  //       if (error.response?.data?.data?.length !== 0) {
  //         swal({
  //           title: 'Failed',
  //           text: `${error.response?.data?.message || 'Unknown error'}`,
  //           icon: 'error',
  //           timer: 3000,
  //           buttons: false,
  //         });
  //       }
  //     }
  //   };
  
  //   GetResponseDataPatientSSP();
  // }, [getDataUserAwal?.id]);
  

  useEffect(() => {
    GetResponseDataUsers()
  }, [getDataUserAwal?.id])

  const [form, setForm] = useState({
    // data awal
    first_name: "",
    last_name: "",
    residence_address: "",
    phone_number: "",
    day_birth: "",
    month_birth: "",
    year_birth: "",
    gender: "",
    // data update
    medical_record_code: "",
    nic_number: "",
    nic_address:"",
    // province: "",
    // regency: "",
    // district: "",
    // sub_district: "",
    status: "",
    education: "",
    token_satu_sehat: "",
    occupation: "",
    nationality: ""

  });


  useEffect(() => {
    setForm({
    ...form,
      first_name: `${getDataUserAwal?.first_name}`,
      last_name: `${getDataUserAwal?.last_name}`,
      residence_address: `${getDataUserAwal?.residence_address}`,
      phone_number: `${getDataUserAwal?.phone_number}`,
      day_birth: `${getDataUserAwal?.date_birth}`.split("-")[2], 
      month_birth: `${getDataUserAwal?.date_birth}`.split("-")[1],
      year_birth: `${getDataUserAwal?.date_birth}`.split("-")[0],
      gender: `${getDataUserAwal?.gender}`,
      // data yang harus di update
      medical_record_code: `${getDataUserAwal?.medical_record_code}`,
      nic_number: `${getDataUserAwal?.nic_number}`,
      nic_address: `${getDataUserAwal?.nic_address}`,
      // province: `${getDataUserAwal?.province}`,
      // regency: `${getDataUserAwal?.regency}`,
      // district: `${getDataUserAwal?.district}`,
      // sub_district: `${getDataUserAwal?.sub_district}`,
      status: `${getDataUserAwal?.status}`,
      education: `${getDataUserAwal?.education}`,
      occupation: `${getDataUserAwal?.occupation}`,
      nationality: `${getDataUserAwal?.nationality}`,
    });
  }, [getDataUserAwal])
    

  // Fetch Provinces (Provinsi)
  useEffect(() => {
    const fetchDataProvince = async () => {

      try {
        setLoading(true);
        const response = await API.get(`user-service/masterdata/v1/provinces?limit=1000`, fetchParams);
        if (response?.status === 200) {
          setProvinceLoaded(true);

          setGetProvince(response.data.data.data);
        }
      } catch (error) {
        swal({
          title: 'Failed',
          text: error.response?.message || "Error fetching provinces",
          icon: 'error',
          timer: 3000,
          buttons: false,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchDataProvince();
  }, []);
  
  const fetchRegencies = async (provinceCode) => {
    try {
        setLoading(true);
        const response = await API.get(`user-service/masterdata/v1/cities/${provinceCode}?limit=1000`, fetchParams);
        if (response?.status === 200) {
          setGetRegency(response.data.data.data);
        }
    } catch (error) {
      if (error.response.data?.data !== null) {
        swal({
          title: "Failed",
          text: error.response?.message || "Error fetching regencies",
          icon: "error",
          timer: 3000,
          buttons: false,
        });
      }
    } finally {
      setLoading(false);
    }
  };
   
  const fetchDistricts = async (regencyCode) => {
    try {
      if (regencyCode !== null) {
        setLoading(true);
        const response = await API.get(`user-service/masterdata/v1/regencies/${regencyCode}?limit=8000000`, fetchParams);
        if (response?.status === 200) {
          setGetDistrict(response.data.data.data);
        }
      }
    } catch (error) {
      swal({
        title: "Failed",
        text: error.response?.message || "Error fetching districts",
        icon: "error",
        timer: 3000,
        buttons: false,
      });
    } finally {
      setLoading(false);
    }
  };
  
  const fetchSubDistricts = async (districtCode) => {
    try {
      if (districtCode !== null) {
        setLoading(true);
        const response = await API.get(`user-service/masterdata/v1/districts/${districtCode}?limit=10000000000`, fetchParams);
        if (response?.status === 200) {
          setGetSubDistrict(response.data.data.data);
        }
      }
    } catch (error) {
      swal({
        title: "Failed",
        text: error.response?.message || "Error fetching sub-districts",
        icon: "error",
        timer: 3000,
        buttons: false,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setSelectedProvince(getDataUserAwal?.province || "");
    setSelectedRegency(getDataUserAwal?.regency || "");
    setSelectedDistrict(getDataUserAwal?.district || "");
    setSelectedSubDistrict(getDataUserAwal?.sub_district || "");

  }, [getDataUserAwal?.regency]);

 
  
  useEffect(() => {
    if (getRegency?.length > 0 && getDataUserAwal?.regency) {
      // Cari nama regency berdasarkan kode
      const regencyData = getRegency.find(
        (item) => item.code === getDataUserAwal.regency
      );
      // Jika ditemukan, simpan namanya
      if (regencyData) {
        setForm((prevForm) => ({
          ...prevForm,
          regency_name: regencyData.name,
        }));
      }
    }
  }, [getRegency, getDataUserAwal?.regency]);

  // update region
  
  

  useEffect(() => {
    const updateFetchRegencies = async () => {
      if (!getDataUserAwal?.province) {
        console.warn("City is undefined or null, skipping API call.");
        return;
      }
      try {
        setLoading(true);

        const response = await API.get(
          `user-service/masterdata/v1/cities/${getDataUserAwal?.province}?limit=1000
          `,
          fetchParams
        );
        
        if (response?.status === 200) {
          if (response.data.data && response.data.data.data !== null) {
            const foundRegency = response.data.data.data.find(
              item => item.id === getDataUserAwal?.regency
            );
            setPutSelectedRegency(foundRegency?.name)
          }
        }
          
    
      } catch (error) {
        swal({
          title: "Failed",
          text: error.response?.data?.message || error.message || "Error fetching regencies",
          icon: "error",
          timer: 3000,
          buttons: false,
        });
      } finally {
        setLoading(false);
      }
    };
    
    const updateFetchDistricts = async () => {
      try {
        if (!getDataUserAwal?.regency) {
          console.warn("Regency is undefined or null, skipping API call.");
          return;
        }
        if (getDataUserAwal?.regency !== undefined) {
          setLoading(true);
          const response = await API.get(`user-service/masterdata/v1/regencies/${getDataUserAwal?.regency}?limit=1000`, fetchParams);
          if (response?.status === 200) {
            if (response.data.data && response.data.data.data !== null) {
              const foundDistrict = response.data.data.data.find(
                item => item.id === getDataUserAwal?.district
              );
              setPutSelectedDistrict(foundDistrict?.name)
            }
          }
        }
      } catch (error) {
        swal({
          title: "Failed",
          text: error.response?.data?.message || error.message || "Error fetching regencies",
          icon: "error",
          timer: 3000,
          buttons: false,
        });
      } finally {
        setLoading(false);
      }
    };

    const updateFetchSubDistrict = async () => {
      try {

        if (!getDataUserAwal?.district) {
          console.warn("District is undefined or null, skipping API call.");
          return;
        }
        if (getDataUserAwal.district !== undefined) {
          setLoading(true);
          const response = await API.get(`user-service/masterdata/v1/districts/${getDataUserAwal?.district}?limit=10000000000`, fetchParams);
          if (response?.status === 200) {
            if (response.data.data && response.data.data.data !== null) {
              const foundSubDistrict = response.data.data.data.find(
                item => item.id === getDataUserAwal?.sub_district
              );
              setPutSelectedSubDistrict(foundSubDistrict?.name)
            }
        }
        } else {
          throw new Error('Unexpected response status');
        }
      } catch (error) {
        swal({
          title: "Failed",
          text: error.response?.data?.message || error.message || "Error fetching regencies",
          icon: "error",
          timer: 3000,
          buttons: false,
        });
      } finally {
        setLoading(false);
      }
    };
  
    updateFetchRegencies();
    updateFetchDistricts();
    updateFetchSubDistrict();
  }, [getDataUserAwal?.province, getDataUserAwal?.regency, getDataUserAwal?.district]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
  
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
      ...(name === "province" && {
        regency: "", // Reset regency
        district: "", // Reset district
        sub_district: "" // Reset sub_district
      }),
      ...(name === "regency" && {
        district: "", // Reset district
        sub_district: "" // Reset sub_district
      }),
      ...(name === "district" && {
        sub_district: "" // Reset sub_district
      }),
    }));
    
    if (name === "province") {
      fetchRegencies(value);
      setSelectedProvince(value);
      setSelectedRegency("");
      setSelectedDistrict("");
      setSelectedSubDistrict("");
    } else if (name === "regency") {
      fetchDistricts(value);
      setSelectedRegency(value);
      setSelectedDistrict("");
      setSelectedSubDistrict("");
    } else if (name === "district") {
      fetchSubDistricts(value);
      setSelectedDistrict(value);
      setSelectedSubDistrict("");
    } else if (name === "sub_district") {
      setSelectedSubDistrict(value);
    } else {
      // Reset semua pilihan jika ada nama lain yang diberikan
      setSelectedProvince("");
      setSelectedRegency("");
      setSelectedDistrict("");
      setSelectedSubDistrict("");
    }
  };


  // const handleChange = (e) => {
  //   setForm({
  //     ...form,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const handleSubmit = useMutation(async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
  
      // Validasi data wilayah
      // if (!selectedProvince || !selectedRegency || !selectedDistrict || !selectedSubDistrict) {
      //   swal({
      //     title: "Warning",
      //     text: "Pastikan semua data wilayah sudah dipilih.",
      //     icon: "warning",
      //     timer: 3000,
      //     buttons: false,
      //   });
      //   setLoading(false);
      //   return;
      // }
  
      // Payload untuk request API
      const payload = {
        first_name: form?.first_name,
        last_name: form?.last_name,
        gender: form?.gender,
        residence_address: form?.residence_address,
        phone_number: form?.phone_number,
        date_birth: form?.year_birth && form?.month_birth && form?.day_birth 
          ? `${form?.year_birth}-${form?.month_birth}-${form?.day_birth}` 
          : "",
        medical_record_code: form?.medical_record_code,
        nic_number: form?.nic_number,
        nic_address: form?.nic_address,
        province: selectedProvince || getDataUserAwal?.province,
        regency: selectedRegency || getDataUserAwal?.regency,
        district: selectedDistrict || getDataUserAwal?.district,
        sub_district: selectedSubDistrict || getDataUserAwal?.sub_district,
        status: form?.status,
        education: form?.education,
        occupation: form?.occupation,
        nationality: form?.nationality,
      };
  
      // Kirim request ke server
      const response = await API.put(
        `/transaction-service/patients/${props.idUpdatePatient}`,
        payload,
        fetchParams
      );
  
      // Respons sukses
      if (response?.status === 200) {
        swal({
          title: "Success",
          text: response.data.message,
          icon: "success",
          timer: 5000,
          buttons: false,
        });
  
        // Refresh data pengguna
        GetResponseDataUsers();
  
        // Reset state loading
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
  
      swal({
        title: "Failed",
        text: error.response?.data?.message || "Gagal memperbarui data.",
        icon: "error",
        timer: 3000,
        buttons: false,
      });
    }
  });
  
  

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{fontFamily:"sans-serif",border:"none"}}>
      {loading && <LoaderHome />}

      <div style={{ width: "100%", display: "flex", padding: "10px 0px", backgroundColor: "#29B8FF" }}>
        <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", paddingLeft:"10px", color:"white", fontWeight:"600"}}>
          Update Data Patient
        </div> 
        <div  style={{flex:"8%",fontSize:"30px",display:"flex",alignItems:"center",justifyContent:"center", color:"white"}}>
          <FaTimes onClick={() => setProopsData(props.onHide)} style={{cursor:"pointer"}}/>
        </div> 
      </div>
      <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px",border:"none" }}>
     
      <Form onSubmit={(e) => handleSubmit.mutate(e)} className="mt-3" >
        <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Nama Depan
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
              <input autoFofcus type='text' value={form?.first_name} name="first_name" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
            </div>
          </div>
          </div>
          
          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Nama Belakang
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
              <input autoFofcus type='text' value={form?.last_name} name="last_name" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
            </div>
          </div>
          </div>

          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Nomor HP
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
              <input autoFofcus type='text' value={form?.phone_number} name="phone_number" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
            </div>
          </div>
          </div>

          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Alamat
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
              <input autoFofcus type='text' value={form?.residence_address} name="residence_address" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
            </div>
          </div>
          </div>

          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Jenis Kelamin
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <select className="form-select" aria-label="Default select example" onChange={handleChange} value={form?.gender}  name="gender" style={{ textAlign:"", cursor:"pointer"}}>
              <option value="" hidden>Select</option>
              <option 
                value="male" 
                style={{ textAlign: "" }} 
                selected={getDataUserAwal?.gender === "male"}
              >
                Laki-Laki
              </option>
              <option 
                value="female" 
                style={{ textAlign: "" }} 
                selected={getDataUserAwal?.gender === "female"}
              >
                Wanita
              </option>
  
            </select>
          </div>
          </div>
          
          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%",display:"flex", alignItems:"center"}}>
            Tanggal Lahir
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "5px", height: "100%", display: "flex", alignItems: "center" }}>:</p>
                      
            <select className="form-select" aria-label="Default select example" value={form?.day_birth} onChange={handleChange}  name="day_birth" style={{ textAlign:"center", cursor:"pointer", fontSize:"13px"}}>
              <option value="" hidden>Day</option>
              <option value="01" style={{textAlign:"center"}}>1</option>
              <option value="02" style={{textAlign:"center"}}>2</option>
              <option value="03" style={{textAlign:"center"}}>3</option>
              <option value="04" style={{textAlign:"center"}}>4</option>
              <option value="05" style={{textAlign:"center"}}>5</option>
              <option value="06" style={{textAlign:"center"}}>6</option>
              <option value="07" style={{textAlign:"center"}}>7</option>
              <option value="08" style={{textAlign:"center"}}>8</option>
              <option value="09" style={{textAlign:"center"}}>9</option>
              <option value="10" style={{textAlign:"center"}}>10</option>
              <option value="11" style={{textAlign:"center"}}>11</option>
              <option value="12" style={{textAlign:"center"}}>12</option>
              <option value="13" style={{textAlign:"center"}}>13</option>
              <option value="14" style={{textAlign:"center"}}>14</option>
              <option value="15" style={{textAlign:"center"}}>15</option>
              <option value="16" style={{textAlign:"center"}}>16</option>
              <option value="17" style={{textAlign:"center"}}>17</option>
              <option value="18" style={{textAlign:"center"}}>18</option>
              <option value="19" style={{textAlign:"center"}}>19</option>
              <option value="20" style={{textAlign:"center"}}>20</option>
              <option value="21" style={{textAlign:"center"}}>21</option>
              <option value="22" style={{textAlign:"center"}}>22</option>
              <option value="23" style={{textAlign:"center"}}>23</option>
              <option value="24" style={{textAlign:"center"}}>24</option>
              <option value="25" style={{textAlign:"center"}}>25</option>
              <option value="26" style={{textAlign:"center"}}>26</option>
              <option value="27" style={{textAlign:"center"}}>27</option>
              <option value="28" style={{textAlign:"center"}}>28</option>
              <option value="29" style={{textAlign:"center"}}>29</option>
              <option value="30" style={{textAlign:"center"}}>30</option>
              <option value="31" style={{textAlign:"center"}}>31</option>    
            </select>
                      
            <select className="form-select" aria-label="Default select example" value={form?.month_birth} onChange={handleChange}  name="month_birth"  style={{ textAlign:"center", cursor:"pointer", fontSize:"13px"}}>
              <option value="" hidden>Month</option>
              <option value="01" style={{ textAlign:"center"}}>Januari</option>
              <option value="02" style={{textAlign:"center"}}>Februari</option>
              <option value="03" style={{textAlign:"center"}}>Maret</option>
              <option value="04" style={{textAlign:"center"}}>April</option>
              <option value="05" style={{textAlign:"center"}}>Mei</option>
              <option value="06" style={{textAlign:"center"}}>Juni</option>
              <option value="07" style={{textAlign:"center"}}>Juli</option>
              <option value="08" style={{textAlign:"center"}}>Agustus</option>
              <option value="09" style={{textAlign:"center"}}>September</option>
              <option value="10" style={{textAlign:"center"}}>Oktober</option>
              <option value="11" style={{textAlign:"center"}}>November</option>
              <option value="12" style={{textAlign:"center"}}>Desember</option>    
            </select> 
                      
            <select className="form-select" aria-label="Default select example" value={form?.year_birth} onChange={handleChange}  name="year_birth"  style={{ textAlign:"center", cursor:"pointer", fontSize:"13px"}}>
            <option value="" hidden>Year</option>
            <option value="1940">1940</option>
            <option value="1941">1941</option>
            <option value="1942">1942</option>
            <option value="1943">1943</option>
            <option value="1944">1944</option>
            <option value="1945">1945</option>
            <option value="1946">1946</option>
            <option value="1947">1947</option>
            <option value="1948">1948</option>
            <option value="1949">1949</option>
            <option value="1950">1950</option>
            <option value="1951">1951</option>
            <option value="1952">1952</option>
            <option value="1953">1953</option>
            <option value="1954">1954</option>
            <option value="1955">1955</option>
            <option value="1956">1956</option>
            <option value="1957">1957</option>
            <option value="1958">1958</option>
            <option value="1959">1959</option>
            <option value="1960">1960</option>
            <option value="1961">1961</option>
            <option value="1962">1962</option>
            <option value="1963">1963</option>
            <option value="1964">1964</option>
            <option value="1965">1965</option>
            <option value="1966">1966</option>
            <option value="1967">1967</option>
            <option value="1968">1968</option>
            <option value="1969">1969</option>
            <option value="1970">1970</option>
            <option value="1971">1971</option>
            <option value="1972">1972</option>
            <option value="1973">1973</option>
            <option value="1974">1974</option>
            <option value="1975">1975</option>
            <option value="1976">1976</option>
            <option value="1977">1977</option>
            <option value="1978">1978</option>
            <option value="1979">1979</option>
            <option value="1980">1980</option>
            <option value="1981">1981</option>
            <option value="1982">1982</option>
            <option value="1983">1983</option>
            <option value="1984">1984</option>
            <option value="1985">1985</option>
            <option value="1986">1986</option>
            <option value="1987">1987</option>
            <option value="1988">1988</option>
            <option value="1989">1989</option>
            <option value="1990">1990</option>
            <option value="1991">1991</option>
            <option value="1992">1992</option>
            <option value="1993">1993</option>
            <option value="1994">1994</option>
            <option value="1995">1995</option>
            <option value="1996">1996</option>
            <option value="1997">1997</option>
            <option value="1998">1998</option>
            <option value="1999">1999</option>
            <option value="2000">2000</option>
            <option value="2001">2001</option>
            <option value="2002">2002</option>
            <option value="2003">2003</option>
            <option value="2004">2004</option>
            <option value="2005">2005</option>
            <option value="2006">2006</option>
            <option value="2007">2007</option>
            <option value="2008">2008</option>
            <option value="2009">2009</option>
            <option value="2010">2010</option>
            <option value="2011">2011</option>
            <option value="2012">2012</option>
            <option value="2013">2013</option>
            <option value="2014">2014</option>
            <option value="2015">2015</option>
            <option value="2016">2016</option>
            <option value="2017">2017</option>
            <option value="2018">2018</option>
            <option value="2019">2019</option>
            <option value="2020">2020</option>
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>         
            </select>
          </div>
          </div>

          <div className="mt-2" style={{ display: "flex"}}>
            <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
              Nomor Rekam Medis
            </div>
            <div style={{ flex: "60%", display:"flex"}}>
              <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
              <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
                <input  type='text' value={form.medical_record_code === "null" || form.medical_record_code === "undefined" || form.medical_record_code === null || form.medical_record_code === undefined? "-" : form?.medical_record_code}   name="medical_record_code" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
              </div>
            </div>
          </div>
          
          <div className="mt-2" style={{ display: "flex"}}>
            <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
              Nomor KTP
            </div>
            <div style={{ flex: "60%", display:"flex"}}>
              <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
              <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
                <input autoFofcus type='text' value={form.nic_number === "null" || form.nic_number === "undefined" || form.nic_number === null || form.nic_number === undefined? "-" : form?.nic_number} name="nic_number" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
              </div>
            </div>
          </div>

        {/* start region */}
          {/* Province */}
          <div className="mt-2" style={{ display: "flex" }}>
            <div style={{ flex: "5%", display: "flex", alignItems: "center" }}>
              Alamat KTP
              <div style={{ color: "red", fontSize: "18px" }}>*</div>
            </div>
            <div style={{ flex: "60%", display: "flex" }}>
              <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
              <div style={{ width: "100%", outline: "none", padding: "10px 0px" }}>
                <select
                  name="province"
                  value={selectedProvince}
                  onChange={handleChange}
                  style={{
                    width: "100%",
                    height: "100%",
                    paddingLeft: "0px",
                    borderTop: "none",
                    borderRight: "none",
                    borderLeft: "none",
                    borderBottom: `1px solid #B6B6B6`,
                    outline: "none",
                  }}
                >
                  <option value="">Pilih Provinsi</option>
                  {getProvince?.length > 0 ? (
                    getProvince.map((province) => (
                      <option key={province.code} value={province.code}>
                        {province.name}
                      </option>
                    ))
                  ) : (
                    <option value={getDataUserAwal?.province}>
                      {getDataUserAwal?.province}
                    </option>
                  )}
                </select>
              </div>
            </div>
          </div>

          {/* Regency */}
          <div className="mt-2" style={{ display: "flex" }}>
            <div style={{ flex: "5%", display: "flex", alignItems: "center" }}></div>
            <div style={{ flex: "60%", display: "flex" }}>
              <p
                style={{
                  marginRight: "10px",
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                :
              </p>
              <div style={{ width: "100%", outline: "none", padding: "10px 0px" }}>
                <select
                  name="regency"
                  value={selectedRegency}
                  onChange={handleChange}
                  disabled ={!selectedProvince}
                  style={{
                    width: "100%",
                    height: "100%",
                    paddingLeft: "0px",
                    borderTop: "none",
                    borderRight: "none",
                    borderLeft: "none",
                    borderBottom: `1px solid #B6B6B6`,
                    outline: "none",
                  }}
                >
                  <option value="">Pilih Kabupaten/Kota</option>
                  {getRegency?.length > 0 ? (
                    getRegency.map((regency) => (
                      <option key={regency.code} value={regency.id}>
                        {regency.name}
                      </option>
                    ))
                  ) : (
                    <option value={getDataUserAwal?.regency}>
                      {putSelectedRegency || "Pilih provinsi terlebih dahulu!"}
                    </option>
                  )}
                </select>
              </div>
            </div>
          </div>

          {/* District */}
          <div className="mt-2" style={{ display: "flex" }}>
            <div style={{ flex: "5%", display: "flex", alignItems: "center" }}></div>
            <div style={{ flex: "60%", display: "flex" }}>
              <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
              <div style={{ width: "100%", outline: "none", padding: "10px 0px" }}>
              <select
                name="district"
                value={selectedDistrict}
                onChange={handleChange}
                disabled ={!selectedRegency}
                style={{
                  width: "100%",
                  height: "100%",
                  paddingLeft: "0px",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: `1px solid #B6B6B6`,
                  outline: "none",
                }}
              >
                <option value="">Pilih Kecamatan</option>
                <option value={getDataUserAwal?.district}>
                  {putSelectedDistrict || "Pilih kabupaten/kota terlebih dahulu!"}
                </option>
                {getDistrict?.length > 0 && (
                  getDistrict.map((district) => (
                    <option key={district.code} value={district.id}>
                      {district.name}
                    </option>
                  ))
                )}
              </select>
              </div>
            </div>
          </div>

          {/* Sub District */}
          <div className="mt-2" style={{ display: "flex" }}>
            <div style={{ flex: "5%", display: "flex", alignItems: "center" }}></div>
            <div style={{ flex: "60%", display: "flex" }}>
              <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
              <div style={{ width: "100%", outline: "none", padding: "10px 0px" }}>
                <select
                  name="sub_district"
                  value={selectedSubDistrict}
                  onChange={handleChange}
                  disabled ={!selectedDistrict}
                  style={{
                    width: "100%",
                    height: "100%",
                    paddingLeft: "0px",
                    borderTop: "none",
                    borderRight: "none",
                    borderLeft: "none",
                    borderBottom: `1px solid #B6B6B6`,
                    outline: "none",
                  }}
                >
                  <option value="">Pilih Kelurahan/Desa</option>
                  <option value={getDataUserAwal?.sub_district}>
                    {putSelectedSubDistrict || "Pilih kecamatan terlebih dahulu!"}
                  </option>
                  {getSubDistrict?.length > 0 && (
                    getSubDistrict.map((subDistrict) => (
                      <option key={subDistrict.code} value={subDistrict.id}>
                        {subDistrict.name}
                      </option>
                    )))}
                </select>

              </div>
            </div>
          </div>
        {/* end region */}


          {/* <div className="mt-2" style={{ display: "flex"}}>
            <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
              Alamat KTP
            </div>
            <div style={{ flex: "60%", display:"flex"}}>
              <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
              <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
                <input autoFofcus type='text' value={form.nic_address === "null" || form.nic_address === "undefined" || form.nic_address === null || form.nic_address === undefined? "-" : form?.nic_address} name="nic_address" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
              </div>
            </div>
          </div> */}
          <div className="mt-2" style={{ display: "flex"}}>
            <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
              Status
            </div>
            <div style={{ flex: "60%", display:"flex"}}>
              <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
              <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
                <input autoFofcus type='text' value={form.status === "null" || form.status === "undefined" || form.status === null || form.status === undefined? "-" : form?.status} name="status" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
              </div>
            </div>
          </div>

          <div className="mt-2" style={{ display: "flex"}}>
            <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
              Pendidikan
            </div>
            <div style={{ flex: "60%", display:"flex"}}>
              <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
              <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
                <input autoFofcus type='text' value={form.education === "null" || form.education === "undefined" || form.education === null || form.education === undefined? "-" : form?.education} name="education" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
              </div>
            </div>
          </div>

          <div className="mt-2" style={{ display: "flex"}}>
            <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
              Pekerjaan
            </div>
            <div style={{ flex: "60%", display:"flex"}}>
              <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
              <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
                <input autoFofcus type='text' value={form.occupation === "null" || form.occupation === "undefined" || form.occupation === null || form.occupation === undefined? "-" : form?.occupation} name="occupation" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
              </div>
            </div>
          </div>

          <div className="mt-2" style={{ display: "flex"}}>
            <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
              Berkebangsaan
            </div>
            <div style={{ flex: "60%", display:"flex"}}>
              <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
              <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
                <input autoFofcus type='text' value={form.nationality === "null" || form.nationality === "undefined" || form.nationality === null || form.nationality === undefined? "-" : form?.nationality} name="nationality" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
              </div>
            </div>
          </div>
        
          <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"end" }}>
            <div>
              <Button className="mt-4" type='submit' color='primary' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
                Update
              </Button>
            </div>
          </div>
        </Form>
        
      </Modal.Body>
      {/* <Modal.Footer>
        <div style={{ display: "flex" }}>
          <Button onClick={props.onHide} style={{marginRight:"8px"}}>Close</Button>
        </div>
      </Modal.Footer> */}
    </Modal>
    );
}
  