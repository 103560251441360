/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API } from "../../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaClosedCaptioning, FaTimes, FaBold, FaItalic, FaStrikethrough, FaMicrophone, FaTelegram, FaTelegramPlane} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import "bulma/css/bulma.css";
import "../Styled.css"
import Swal from "sweetalert2";
import LoaderHome from "../../Loader/LoaderHome"
import swal from "sweetalert";
import React from 'react';
import logo_kemenkes from "../../../assets/signature/logo_kemeskes.png"
import axios from "axios";

export default function ModalRoleAdd(props) {
  // untuk code uuid ini :  100465661 merupakan organisasi ID GRATIA
  // untuk code uuid ini : 
  const token = localStorage.getItem("token");
  // Untuk Close Proops Data;

  const [loading, setLoading] = useState(false);
  const [getDataPatients, setGetDataPatients] = useState([]);
  const [getDataDoctorByID, setGetDataDoctorByID] = useState([]);
  const [getDataPhysicalCheck, setGetDataPhysicalCheck] = useState([]);
  const [getDataAppointmentSsp, setGetDataAppointmentSsp] = useState([]);
  const [dataMedicalRecord, setGetDataMedicalRecord] = useState("");
  const [dataRoomSatuSehat, setGetDataRoomSatuSehat] = useState("");
  const [getDataAppointmentById, setGetDataAppointmentById] = useState(null);

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const [form, setForm] = useState({
    id_appointment: "",
    id_patient: "",
    id_doctor: "",
    patient_name: "",
    token_satu_sehat: "",
    date_appointment: "",
    time_appointment: "",
    arrangement_data: "",
    appointment_ssp: ""
  });

  const GetDataResponseRoomFromSatuSehat = async () => {
    try {
      const fetchParamss = {
        headers: {
          'Authorization': `Bearer ${form?.token_satu_sehat.access_token}`,
          'Content-Type': 'application/json',
        }
      };

      setLoading(true)
      // Insert data for login process
      const response = await axios.get(`https://api-satusehat.kemkes.go.id/fhir-r4/v1/Location?identifier=http://sys-ids.kemkes.go.id/location/100465661|${form?.arrangement_data.id_room}`, fetchParamss);
      
      // Checking process
      if (response?.data.entry.length !== 0) {
        setGetDataRoomSatuSehat(response.data.entry[0].resource)
        setLoading(false)
      }
    }catch(error) {
      setLoading(false)
      // swal({
      //   title: 'Failed',
      //   // text: `${error.response.data.message}`,
      //   text: `error`,
      //   icon: 'error',
      //   timer: 3000,
      //   buttons: false
      // });
    } 
  }

  
  const GetResponseDataPatients = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/transaction-service/patients/${form?.id_patient}`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataPatients(response.data.data)
        // setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataMedicalRecord = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/emr-service/medical-invoices/${form?.id_appointment}`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataMedicalRecord(response.data.data)
        // setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataDoctorByID = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/user-service/privileges/users/${form?.id_doctor}`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataDoctorByID(response.data.data)
        // setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataPhisicalChecks = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/emr-service/phy-checks/patient/${form?.id_appointment}`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataPhysicalCheck(response.data.data)
        // setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataAppointmentSSPByIdAppointment = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/user-service/appointment-ssp/${form?.id_appointment}`,fetchParams)
      if (response?.status === 200) {
          setGetDataAppointmentSsp(response.data.data);
          setLoading(false);
      }
    } catch (error) {
      setLoading(false)
      if (error.response.data.data.length !== 0) {
        swal({
          title: 'Failed',
          text: `${error.response.data.message}`,
          icon: 'error',
          timer: 3000,
          buttons: false
        });
      }
    }
  }

  useEffect(() => {
    setForm({
      ...form, 
      id_appointment: props?.idAppointmentModalSatuSehat === null? "" : props?.idAppointmentModalSatuSehat,
      id_patient: props?.idPatientModalSatuSehat === null? "" : props?.idPatientModalSatuSehat,
      id_doctor: props?.idDoctorModalSatuSehat === null? "" : props?.idDoctorModalSatuSehat,
      patient_name: props?.patientNameModalSatuSehat === null? "" : props?.patientNameModalSatuSehat,
      token_satu_sehat: props?.getDataSatuSehat === null? "" : props?.getDataSatuSehat,
      date_appointment: props?.dateSatuSehat === null? "" : props?.dateSatuSehat,
      time_appointment: props?.timeSatuSehat === null? "" : props?.timeSatuSehat,
      arrangement_data: props?.arrangementSatuSehat === null? "" : props?.arrangementSatuSehat,
      appointment_ssp: props?.appointmentSSP === null? "" : props?.appointmentSSP 
    });
  }, [props])

  useEffect(() => {
    if(form?.id_patient !== "" && form?.id_doctor !== "" && form?.id_appointment !== "" && form?.date_appointment !== "" && form?.time_appointment !== "" && form?.arrangement_data !== ""){
      GetResponseDataPatients()
      GetResponseDataDoctorByID()
      GetResponseDataPhisicalChecks()
      GetResponseDataMedicalRecord()
      GetDataResponseRoomFromSatuSehat()
      GetResponseDataAppointmentSSPByIdAppointment()
    }
  }, [form?.id_patient, form?.id_doctor, form?.id_appointment])

  const PostDataAppointmentSSPLocal = async (id, patient_id) => {
    try {
      // e.preventDefault();
      setLoading(true)
      // Insert data for login process
      const response = await API.post("/user-service/appointment-ssp/store", {
        id_appointment: form?.id_appointment,
        id_appointment_ssp: id,
        id_patient_ssp: patient_id,
        status_sistole_ssp: 0,
        status_distole_ssp: 0,
        status_observation_ssp: 0,
        status_diagnosis_ssp: 0,
        status_procedure_ssp: 0,
      }, fetchParams);
  
      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })
        // window.location.reload()
        props.GetResponseData()
        props.onHide()
        setLoading(false)
      }

    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false)
    }
    
  };
  
  const UpdateDataSistoleSSPLocal = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.put(`/user-service/appointment-ssp/${form?.id_appointment}`, {
        // status_sistole_ssp: true,
        status_sistole_ssp: 1,
      }, fetchParams);

      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })
        // window.location.reload()
        props.GetResponseData()
        props.onHide()
        setLoading(false)
      }

    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false)
    }
    
  };

  const UpdateDataDistoleSSPLocal = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      // Insert data for login process
      const response = await API.put(`/user-service/appointment-ssp/${form?.id_appointment}`, {
        // status_distole_ssp: true,
        status_distole_ssp: 1,
      }, fetchParams);
  
      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })
        // window.location.reload()
        props.GetResponseData()
        props.onHide()
        setLoading(false)
      }

    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false)
    }
    
  };

  const UpdateDataObservationSSPLocal = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      // Insert data for login process
      const response = await API.put(`/user-service/appointment-ssp/${form?.id_appointment}`, {
        status_observation_ssp: 1,
      }, fetchParams);
  
      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })
        // window.location.reload()
        props.GetResponseData()
        props.onHide()
        setLoading(false)
      }

    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false)
    }
    
  };

  const UpdateDataDiagnosisSSPLocal = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      // Insert data for login process
      const response = await API.put(`/user-service/appointment-ssp/${form?.id_appointment}`, {
        // status_diagnosis_ssp: true,
        status_diagnosis_ssp: 1,
      }, fetchParams);
  
      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })
        // window.location.reload()
        props.GetResponseData()
        props.onHide()
        setLoading(false)
      }

    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false)
    }
    
  };
  
  const UpdateDataProcedureSSPLocal = async () => {
    try {
      setLoading(true)
      const response = await API.put(`/user-service/appointment-ssp/${form?.id_appointment}`, {
        status_procedure_ssp: 1,
      }, fetchParams);
      
  
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })
        props.GetResponseData()
        props.onHide()
        setLoading(false)
      }

    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false)
    }
    
  };

  
  const PostDataPatientEcounter = async (patient_id) => { 
    try {   
      const fetchParamss = {
        headers: {
          'Authorization': `Bearer ${form?.token_satu_sehat.access_token}`,
          'Content-Type': 'application/json'
        }
      };

      
      // Insert data for login process
      const response = await axios.post(`https://api-satusehat.kemkes.go.id/fhir-r4/v1/Encounter`, 
      {
        resourceType: "Encounter",
        identifier: [
            {
                system: "http://sys-ids.kemkes.go.id/encounter/100465661",
                value: form?.id_appointment
            }
        ],
        status: "arrived",
        class: {
            system: "http://terminology.hl7.org/CodeSystem/v3-ActCode",
            code: "AMB",
            display: "ambulatory"
        },
        subject: {
          reference: `Patient/${patient_id}`,
          display: `${getDataPatients?.first_name} ${getDataPatients?.last_name}`
        },
        participant: [
            {
                type: [
                    {
                        coding: [
                            {
                                system: "http://terminology.hl7.org/CodeSystem/v3-ParticipationType",
                                code: "ATND",
                                display: "attender"
                            }
                        ]
                    }
                ],
                // individual: {
                //     reference: "Practitioner/10018452434",
                //     display: "Dokter Gigi Budiman"
                // }
                individual: {
                  reference: `Practitioner/${getDataDoctorByID?.user_detail?.ihs_number}`,
                  display: `${getDataDoctorByID?.user_detail?.firstname} ${getDataDoctorByID?.user_detail?.lastname}`,
                }
            }
        ],
        period: {
            start: `${form?.date_appointment}T${form?.time_appointment}+00:00`
        },
        location: [
            {
                location: {
                    reference: `Location/${dataRoomSatuSehat.id}`,
                    display: `${dataRoomSatuSehat.name}`
                }
            }
        ],
        statusHistory: [
          {
              status: "arrived",
              period: {
                  start: `${form?.date_appointment}T${form?.time_appointment}+00:00` 
              }
          }
      ],
        serviceProvider: {
            reference: `Organization/100465661`
        }
      },
      fetchParamss);

      

      if (response?.data.status === "arrived") {
        props.GetResponseData()
        PostDataAppointmentSSPLocal(patient_id, response.data.id)
        PostDataPatientSistole(patient_id, response.data.id);
        PostDataPatientDistole(patient_id, response.data.id);
        PostDataPatientSuhu(patient_id, response.data.id);
        PostDataDiagnosis(patient_id, response.data.id);
        if(dataMedicalRecord?.medical_treatment_invoices?.some(invoice => {
          const treatmentData = invoice?.medical_record?.treatment_plan_detail?.treatment_data;
          return treatmentData?.code_icd9 !== null || treatmentData?.name_icd9 !== null;
        })){
          PostDataTindakan(patient_id, response.data.id)
        }         
      }
    }catch(error) {
      if(error.response.status !== 200 || error.response.status !== 201){
        if(form?.appointment_ssp !== null){
  
          if (form?.appointment_ssp?.status_sistole_ssp === 0 || form?.appointment_ssp?.status_sistole_ssp === false) {
            PostDataPatientSistole(patient_id, error.response.data.id);
          }
  
          if (form?.appointment_ssp?.status_distole_ssp === 0 || form?.appointment_ssp?.status_distole_ssp === false) {
            PostDataPatientDistole(patient_id, error.response.data.id);
          }
          
          if (form?.appointment_ssp?.status_observation_ssp === 0 || form?.appointment_ssp?.status_observation_ssp === false) {
            PostDataPatientSuhu(patient_id, error.response.data.id);
          }
          
          if (form?.appointment_ssp?.status_diagnosis_ssp === 0 || form?.appointment_ssp?.status_diagnosis_ssp === false) {
            PostDataDiagnosis(patient_id, error.response.data.id);
          }
  
          if (form?.appointment_ssp?.status_procedure_ssp === 0 ||  form?.appointment_ssp?.status_procedure_ssp === false) {
            if(dataMedicalRecord?.medical_treatment_invoices?.some(invoice => {
              const treatmentData = invoice?.medical_record?.treatment_plan_detail?.treatment_data;
              return treatmentData?.code_icd9 !== null || treatmentData?.name_icd9 !== null;
            })){
                PostDataTindakan(patient_id, error.response.data.id)
              }         
            }
        }
      } 
      if (error.response.data.issue[0].code === "duplicate") {
        if (getDataAppointmentSsp?.status_sistole_ssp === 0) {
          UpdateDataSistoleSSPLocal()
        }
        if (getDataAppointmentSsp?.status_distole_ssp === 0) {
          UpdateDataDistoleSSPLocal()
        }
        if (getDataAppointmentSsp?.status_diagnosis_ssp === 0) {
          UpdateDataDiagnosisSSPLocal()
        }
        if (getDataAppointmentSsp?.status_observation_ssp === 0) {
          UpdateDataObservationSSPLocal()
        }
        if (getDataAppointmentSsp?.status_procedure_ssp === 0) {
          UpdateDataProcedureSSPLocal()
        }
        swal({
          title: 'Warning',
          text: "Appointment sudah terkirim!",
          icon: 'warning',
          timer: 5000,
          buttons: false
        })
      }
      setLoading(false)
      
    } 
  }

  const PostDataPatientSistole = async (patient_id, id) => {
    try {
      const fetchParamss = {
        headers: {
          'Authorization': `Bearer ${form?.token_satu_sehat.access_token}`,
          'Content-Type': 'application/json'
        }
      }

      // Insert data for login processs
      const response = await axios.post(`https://api-satusehat.kemkes.go.id/fhir-r4/v1/Observation`, 
      {
        resourceType: "Observation",
        status: "final",
        category: [
            {
              coding: [
                  {
                      system: "http://terminology.hl7.org/CodeSystem/observation-category",
                      code: "vital-signs",
                      display: "Vital Signs"
                  }
              ]
            } 
        ],
        code: {
            coding: [
                {
                  system: "http://loinc.org",
                  code: "8480-6",
                  display: "Systolic blood pressure"
                }
            ]
        },
        subject: {
            reference: `Patient/${patient_id}`,
            display: `${getDataPatients?.first_name} ${getDataPatients?.last_name}`
        },
        performer: [
            {
              // "reference": "Practitioner/10018452434",
              // "display": "Dokter Gigi Budiman"
              reference: `Practitioner/${getDataDoctorByID?.user_detail?.ihs_number}`,
              display: `${getDataDoctorByID?.user_detail?.firstname} ${getDataDoctorByID?.user_detail?.lastname}`
            }
        ],
        encounter: {
          reference: `Encounter/${form?.id_appointment}`,
        },
        effectiveDateTime: `${getDataPhysicalCheck?.created_at.split(" ")[0]}T${getDataPhysicalCheck?.created_at.split(" ")[1]}+00:00`,
        issued: `${getDataPhysicalCheck?.created_at.split(" ")[0]}T${getDataPhysicalCheck?.created_at.split(" ")[1]}+00:00`,
        bodySite: {
            coding: [
                {
                    system: "http://snomed.info/sct",
                    code: "368209003",
                    display: "Right arm"
                }
            ]
        },
        valueQuantity: {
            value: parseInt(getDataPhysicalCheck?.blood_pressure.split("/")[0]),
            unit: "mm[Hg]",
            system: "http://unitsofmeasure.org",
            code: "mm[Hg]"
        }
    },
      fetchParamss);


    // Checking process

    
    if (response.status === 200 || response.status === 201) {
      props.GetResponseData()

      if (getDataAppointmentSsp.length === 0) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })
      }
    }

  } catch (error) {
      if (error.response.data.issue[0].code !== "duplicate") {
        UpdateDataSistoleSSPLocal()
        swal({
          title: 'Warning',
          text: "Data sistole sudah terkirim",
          icon: 'warning',
          timer: 5000,
          buttons: false
        })
      }
      setLoading(false)
    } 
  }

  const PostDataPatientDistole = async (patient_id, id) => {
    try {
      const fetchParamss = {
        headers: {
          'Authorization': `Bearer ${form?.token_satu_sehat.access_token}`,
          'Content-Type': 'application/json'
        }
      };
 
      // Insert data for login process
      const response = await axios.post(`https://api-satusehat.kemkes.go.idfhir-r4/v1/Observation`, 
      {
        resourceType: "Observation",
        status: "final",
        category: [
            {
              coding: [
                  {
                      system: "http://terminology.hl7.org/CodeSystem/observation-category",
                      code: "vital-signs",
                      display: "Vital Signs"
                  }
              ]
            }
        ],
        code: {
            coding: [
                {
                  system: "http://loinc.org",
                  code: "8462-4",
                  display: "Diastolic blood pressure"
                }
            ]
        },
        subject: {
            reference: `Patient/${patient_id}`,
            display: `${getDataPatients?.first_name} ${getDataPatients?.last_name}`
        },
        performer: [
            {
              // "reference": "Practitioner/10018452434",
              // "display": "Dokter Gigi Budiman"
              
              reference: `Practitioner/${getDataDoctorByID?.user_detail?.ihs_number}`,
              display: `${getDataDoctorByID?.user_detail.firstname} ${getDataDoctorByID?.user_detail.lastname}`
            }
        ],
        encounter: {
          reference:  `Encounter/${form?.id_appointment}`,
        },
        effectiveDateTime: `${getDataPhysicalCheck?.created_at.split(" ")[0]}T${getDataPhysicalCheck?.created_at.split(" ")[1]}+00:00`,
        issued: `${getDataPhysicalCheck?.created_at.split(" ")[0]}T${getDataPhysicalCheck?.created_at.split(" ")[1]}+00:00`,
        bodySite: {
            coding: [
                {
                    system: "http://snomed.info/sct",
                    code: "368209003",
                    display: "Right arm"
                }
            ]
        },
        valueQuantity: {
            value: parseInt(getDataPhysicalCheck?.blood_pressure.split("/")[1]),
            unit: "mm[Hg]",
            system: "http://unitsofmeasure.org",
            code: "mm[Hg]"
        }
    },
      fetchParamss);
      // Checking process
      if (response.status === 200 || response.status === 201) {
        props.GetResponseData()
        if (getDataAppointmentSsp.length === 0) {
          swal({
            title: 'Success',
            text: response.data.message,
            icon: 'success',
            timer: 5000,
            buttons: false
          })
        }

          UpdateDataDistoleSSPLocal()

        // GetResponseData()
        setLoading(false)

      }
    } catch (error) {
      if (error.response.data.issue[0].code !== "duplicate") {
        UpdateDataDistoleSSPLocal()
        swal({
          title: 'Warning',
          text: "Data distole sudah terkirim",
          icon: 'warning',
          timer: 5000,
          buttons: false
        })
      }

      setLoading(false)
    } 
  }

  const PostDataPatientSuhu = async (patient_id, id) => {
    try {
      const fetchParamss = {
        headers: {
          'Authorization': `Bearer ${form?.token_satu_sehat.access_token}`,
          'Content-Type': 'application/json'
        }
      };
      
      // Insert data for login process
      const response = await axios.post(`https://api-satusehat.kemkes.go.id/fhir-r4/v1/Observation`, 
      {
        resourceType: "Observation",
        status: "final",
        code: {
            coding: [
                {
                  system: "http://loinc.org",
                  code: "8310-5",
                  display: "Body temperature"
                }
            ]
        },
        subject: {
            reference: `Patient/${patient_id}`,
            display: `${getDataPatients?.first_name} ${getDataPatients?.last_name}`
        },
        performer: [
            {
              // "reference": "Practitioner/10018452434",
              // "display": "Dokter Gigi Budiman"
              reference: `Practitioner/${getDataDoctorByID?.user_detail?.ihs_number}`,
              display: `${getDataDoctorByID?.user_detail?.firstname} ${getDataDoctorByID?.user_detail?.lastname}`
            }
        ],
        encounter: {
          reference:  `Encounter/${form?.id_appointment}`,
        },
        valueQuantity: {
            value: parseInt(getDataPhysicalCheck?.body_temperature),
            unit: "C",
            system: "http://unitsofmeasure.org",
            code: "Cel"
        }
    },
      fetchParamss);
      // Checking process
      if (response.status === 200 || response.status === 201) {
        props.GetResponseData()

        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })

        if (form?.status_observation_ssp === false || form?.status_observation_ssp === 0) {
          UpdateDataObservationSSPLocal()
        }
        // GetResponseData()
        setLoading(false)
      }
    } catch (error) {
      if (error.response.data.issue[0].code !== "duplicate") {
        UpdateDataObservationSSPLocal()
        swal({
          title: 'Warning',
          text: "Data observation sudah terkirim",
          icon: 'warning',
          timer: 5000,
          buttons: false
        })
      }

      setLoading(false)
    } 
  }

  const PostDataDiagnosis = async (patient_id, id) => {
    try {
      const fetchParamss = {
        headers: {
          'Authorization': `Bearer ${form?.token_satu_sehat.access_token}`,
          'Content-Type': 'application/json'
        }
      };
      // Insert data for login process
      const response = await axios.post(`https://api-satusehat.kemkes.go.id/fhir-r4/v1/Condition`, 
      {
        resourceType: "Condition",
        clinicalStatus: {
          coding: [
              {
                  system: "http://terminology.hl7.org/CodeSystem/condition-clinical",
                  code: "active",
                  display: "Active"
              }
          ]
      },
      category: [
          {
              coding: [
                  {
                      system: "http://terminology.hl7.org/CodeSystem/condition-category",
                      code: "encounter-diagnosis",
                      display: "Encounter Diagnosis"
                  }
              ]
          }
      ],
      code: {
          coding: [
              {
                  system: "http://hl7.org/fhir/sid/icd-10",
                  code: dataMedicalRecord?.medical_treatment_invoices[0]?.medical_record?.code_diagnose,
                  display: dataMedicalRecord?.medical_treatment_invoices[0]?.medical_record?.diagnose
              }
          ]
      },
      subject: {
        reference: `Patient/${patient_id}`,
        display: `${getDataPatients?.first_name} ${getDataPatients?.last_name}`
      },
      encounter: {
          reference: `Encounter/${form?.id_appointment}`
      },
      onsetDateTime: `${getDataPhysicalCheck?.created_at.split(" ")[0]}T${getDataPhysicalCheck?.created_at.split(" ")[1]}+00:00`,
      recordedDate: `${getDataPhysicalCheck?.created_at.split(" ")[0]}T${getDataPhysicalCheck?.created_at.split(" ")[1]}+00:00`
      },
      fetchParamss);

      // Checking process
      if (response.status === 200 || response.status === 201) {
        props.GetResponseData()

        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })


        if (form?.status_diagnosis_ssp === false || form?.status_diagnosis_ssp === 0) {
          UpdateDataDiagnosisSSPLocal()
        }
        setLoading(false)
      }
    } catch (error) {
      if (error.response.data.issue[0].code !== "duplicate" ) {
        if (form?.status_diagnosis_ssp === false || form?.status_diagnosis_ssp === 0) {
          UpdateDataDiagnosisSSPLocal()
          swal({
            title: 'Warning',
            text: "Data diagnosa sudah terkirim",
            icon: 'warning',
            timer: 5000,
            buttons: false
          })
        }
      }
    } 
  }

  const PostDataTindakan = async (patient_id, id) => {
    try {
      const fetchParamss = {
        headers: {
          'Authorization': `Bearer ${form?.token_satu_sehat.access_token}`,
          'Content-Type': 'application/json',
        },
      };
  
      const validCoding = dataMedicalRecord?.medical_treatment_invoices
        ?.flatMap(invoice => {
          const treatmentData = invoice?.medical_record?.treatment_plan_detail?.treatment_data;
          return treatmentData?.code_icd9 && treatmentData?.name_icd9 ? [{
            system: "http://hl7.org/fhir/sid/icd-9-cm",
            code: treatmentData.code_icd9,
            display: treatmentData.name,
          }] : [];
        });
  
      const code = validCoding.length > 0 ? { coding: validCoding } : undefined;
  
      const payload = {
        resourceType: "Procedure",
        status: "completed",
        category: {
          coding: [
            {
              system: "http://snomed.info/sct",
              code: "277132007",
              display: "Therapeutic procedure",
            },
          ],
          text: "Therapeutic procedure",
        },
        code, 
        subject: {
          reference: `Patient/${patient_id}`,
          display: `${getDataPatients?.first_name} ${getDataPatients?.last_name}`,
        },
        encounter: {
          reference: `Encounter/${form?.id_appointment}`,
        },
        performer: [
          {
            actor: {
              reference: `Practitioner/${getDataDoctorByID?.user_detail?.ihs_number}`,
              display: `${getDataDoctorByID?.user_detail?.firstname} ${getDataDoctorByID?.user_detail?.lastname}`,
              // reference: "Practitioner/10018452434",   
              // display: "Dokter Gigi Budiman",
            },
          },
        ],
      };
  
      // Send the request
      const response = await axios.post(`https://api-satusehat.kemkes.go.id/fhir-r4/v1/Procedure`, payload, fetchParamss);
  
      // Handle the response
      if (response.status === 200 || response.status === 201) {
        props.GetResponseData()

        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false,
        });

        if (form?.status_procedure_ssp === false || form?.status_procedure_ssp === 0) {
          UpdateDataProcedureSSPLocal()
        }
        setLoading(false);
      }
    } catch (error) {
      if (error.response.data.issue[0].code !== "duplicate") {
        if (form?.status_procedure_ssp === false || form?.status_procedure_ssp === 0) {
          UpdateDataProcedureSSPLocal()
          swal({
            title: 'Warning',
            text: "Data procedur sudah terkirim",
            icon: 'warning',
            timer: 5000,
            buttons: false
          })
        }
      }
      setLoading(false);
    }
  };

    
  const PostPatient = async (fetchParamss) => {
    try {

      const patientData = {
        resourceType: "Patient",
        meta: {
          profile: [
            "https://fhir.kemkes.go.id/r4/StructureDefinition/Patient"
          ]
        },
        identifier: [
          {
            use: "official",
            system: "https://fhir.kemkes.go.id/id/nik",
            value: getDataPatients?.nic_number || ""
          }
        ],
        active: true,
        name: [
          {
            use: "official",
            text: `${getDataPatients?.first_name} ${getDataPatients?.last_name}` || ""
          }
        ],
        telecom: [
          {
            system: "phone",
            value: getDataPatients?.phone_number || "",
            use: "mobile"
          }
        ],
        gender: getDataPatients?.gender || "",
        birthDate: getDataPatients?.date_birth || "",
        deceasedBoolean: false,
        address: [
          {
            use: "home",
            line: [
              getDataPatients?.residence_address || ""
            ],
            country: "ID",
            extension: [
              {
                url: "https://fhir.kemkes.go.id/r4/StructureDefinition/administrativeCode",
                extension:[
                  {
                    url: "province",
                    valueCode: `${getDataPatients?.province}`
                  },
                  {
                    url: "city",
                    valueCode: `${getDataPatients?.regency}`
                  },
                  {
                    url: "district",
                    valueCode: `${getDataPatients?.district}`
                  },
                  {
                    url: "village",
                    valueCode: `${getDataPatients?.sub_district}`
                  }
                ]
                
              }
            ]
          }
        ],
        maritalStatus: {
          text: getDataPatients?.status || ""
        },
        multipleBirthInteger: 0
      };

      const response = await axios.post(`https://api-satusehat.kemkes.go.id/fhir-r4/v1/Patient`, patientData, fetchParamss);
      return response.data;
    } catch (error) {
      throw error; 
    }
  };
  
  const PostDataPatient = async (patient_id) => {
    try {
      if (
        getDataPatients?.nic_number === null || 
        getDataPatients?.nic_number === "null" || 
        getDataPatients.nic_number.trim() === ""
      ) {
        swal({
          title: 'Failed',
          text: `Harap isi NIK pasien!`,
          icon: 'warning',
          timer: 3000,
          buttons: false,
        });
        return;
      }

      if (
          getDataPatients?.province === null || 
          getDataPatients.province.trim() === "" ||
          getDataPatients?.regency === null|| 
          getDataPatients.regency.trim() === "" ||
          getDataPatients?.district === null || 
          getDataPatients.district.trim() === "" ||
          getDataPatients?.sub_district === null || 
          getDataPatients.sub_district.trim() === ""
      ) {
          swal({
              title: 'Failed',
              text: `Harap lengkapi data alamat (province, regency, district, sub-district)!`,
              icon: 'warning',
              timer: 3000,
              buttons: false,
          });
          return;
      }
    

      const fetchParamss = {
        headers: {
          'Authorization': `Bearer ${form?.token_satu_sehat?.access_token}`,
          'Content-Type': 'application/json'
        }
      };

      const response = await axios.get(
        `https://api-satusehat.kemkes.go.id/fhir-r4/v1/Patient?identifier=https://fhir.kemkes.go.id/id/nik|${getDataPatients?.nic_number}`,
        fetchParamss
      );
      
      if (form?.appointment_ssp === "" && response?.data?.entry.length === 0) {
        await PostPatient(fetchParamss)
        PostDataAppointmentSSPLocal(patient_id, response.data.id)
        // if (getDataAppointmentById) {
        //   await UpdateStatusAppointmentSatuSehat(); 
        // }
      }
      PostDataPatientEcounter(response.data.entry[0].resource.id);
      // Insert data for login process
      if(form?.appointment_ssp === "" && response?.data?.entry.length !== 0) {
        if (getDataAppointmentSsp.length === 0) {
          PostDataAppointmentSSPLocal(patient_id, response.data.id)
        }else {
          PostDataPatientEcounter(response.data.entry[0].resource.id);
        }
      }else if (form?.appointment_ssp?.id_appointment !== null) {
        PostDataPatientEcounter(response.data.entry[0].resource.id);
      }else{
        if (form?.appointment_ssp.status_sistole_ssp !== 1) {
          PostDataPatientSistole(response.data.entry[0].resource.id, form?.appointment_ssp?.id_appointment);
        }
        if (form?.appointment_ssp.status_distole_ssp !== 1) {
          PostDataPatientDistole(response.data.entry[0].resource.id, form?.appointment_ssp?.id_appointment);
        }
        if (form?.appointment_ssp.status_observation_ssp !== 1) {
          PostDataPatientSuhu(response.data.entry[0].resource.id, form?.appointment_ssp?.id_appointment);
        }
        if (form?.appointment_ssp.status_diagnosis_ssp !== 1) {
          PostDataDiagnosis(response.data.entry[0].resource.id, form?.appointment_ssp?.id_appointment);
        }
        if (form?.appointment_ssp.status_procedure_ssp !== 1) {
          if(dataMedicalRecord?.medical_treatment_invoices?.some(invoice => {
            const treatmentData = invoice?.medical_record?.treatment_plan_detail?.treatment_data;
            return treatmentData?.code_icd9 !== null || treatmentData?.name_icd9 !== null;
          })){
            PostDataTindakan(response.data.entry[0].resource.id, form?.appointment_ssp?.id_appointment)
          }
        }

        if (form?.appointment_ssp.status_sistole_ssp === 0) {
          swal({
            title: 'Failed',
            text: `Lengkapi data Physical Check!`,
            icon: 'error',
            timer: 3000,
            buttons: false,
          });
        }

        if (form?.appointment_ssp.status_distole_ssp === 0) {
          swal({
            title: 'Failed',
            text: `Lengkapi data Physical Check!`,
            icon: 'error',
            timer: 3000,
            buttons: false,
          });
        }
        
        if (form?.appointment_ssp.status_observation_ssp === 0) {
          swal({
            title: 'Failed',
            text: `Lengkapi data Physical Check`,
            icon: 'error',
            timer: 3000,
            buttons: false,
          });
        }
      }
    }catch(error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      }); 
    }
  }


  const GetDataAppointmentById = async () => {
    try {
      const response = await API.get(`transaction-service/appointments/${form?.id_appointment}`, fetchParams);
      if (response?.status === 200) {
        setGetDataAppointmentById(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching appointment data', error);
    }
  };

  // Fungsi untuk mengupdate status statusSatuSehat
  // const UpdateStatusAppointmentSatuSehat = async () => {
  //   try {
  //     if (getDataAppointmentById) {
  //       const response = await API.put(`transaction-service/appointments/${form?.id_appointment}`, {
  //         date: getDataAppointmentById?.date,
  //         time: getDataAppointmentById?.time,
  //         anamnesa: getDataAppointmentById?.anamnesa,
  //         status_ssp: 1,
  //       }, fetchParams);
  //       return response.data;
  //     }
  //   } catch (error) {
  //     console.error('Error updating status', error);
  //   }
  // };

  // useEffect(() => {
  //   if (form?.id_appointment) {
  //     GetDataAppointmentById();
  //   }
  // }, [form?.id_appointment]); 


  const handleSubmit = async () => {
    try {
      await PostDataPatient()
  
      // UpdateStatusStatusSatuSehat()
      // swal({
      //   title: 'Mohon maaf',
      //   text: 'Untuk sementara tombol ini masih tahap pengembangan',
      //   icon: 'info',
      //   timer: 3000,
      //   buttons: false
      // });
    } catch (error) {
      swal({
        title: 'Failed',
        text: 'Terjadi kesalahan saat mengirim data.',
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  };
  
  return (
    <Modal {...props}  aria-labelledby="contained-modal-title-center" centered style={{fontFamily:"sans-serif",border:"none"}}>
      {loading && <LoaderHome />}
      <div style={{ width: "100%", display: "flex", padding: "10px 0px", backgroundColor: "#29B8FF" }}>
          
          <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", paddingLeft:"10px", color:"white", fontWeight:"600"}}>
            Satu Sehat || {form?.patient_name}
          </div> 

        <div style={{flex:"8%",fontSize:"30px",display:"flex",alignItems:"center",justifyContent:"center", color:"white"}}>
          <FaTimes onClick={props.onHide} style={{cursor:"pointer"}}/>
        </div> 
      </div>
      <Modal.Body style={{backgroundColor:"",borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px", border:"none" }}>
      <Form className="mt-" >
        <Col xl='12' sm='12'>

        <div style={{display:"flex", justifyContent:"center",  padding:"0px 0px"}}>
          <img src={logo_kemenkes} style={{minWidth:"320px",maxWidth:"320px", minHeight:"250px", maxHeight:"250px", }}/>
        </div>

        <div className="" style={{display:"flex",justifyContent:"center"}}>
          <div>
            <Button className="mt-2" onClick={handleSubmit} color='primary' block style={{ padding: "8px 15px", fontSize: "12px", borderRadius: "5px"}}>
              <FaTelegramPlane/> Kirim Data
            </Button>
          </div>
        </div>
          </Col>
      </Form>
      </Modal.Body>
    </Modal>
    );
}
  