import AdminHome from "../../components/AdminPasien/AdminPasienSatuSehat";
import React from "react";
import { useContext } from "react";
import { UserContext } from "../../context/userContext";
import { useMediaQuery } from 'react-responsive'
import { Row } from "react-bootstrap";
import { Col,  } from 'react-bootstrap';
import Sidebar from "../../components/Sidebar/Sidebar";
import NavbarMobile from "../../components/Navbar/NavbarAdmin/NavbarMobile"
import NavbarDekstop from "../../components/Navbar/NavbarAdmin/NavbarDekstop"

export default function Home() {

  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const [state, dispatch] = useContext(UserContext);
  
  return (
    <div style={{ minHeight: "100vh", display: "flex" }}>
    {isTabletOrMobile ? (
      <Row>
        <Col xl='12' sm='12' style={{ flex: "1" }}>
          <NavbarMobile />
        </Col>
        <Col xl='12' sm='12' style={{ flex: "9" }}>
          <AdminHome />
        </Col>
      </Row>
    ) : (
      <div style={{ display: "flex", flex: "1" }}>
        <div style={{ flex: "10%" }}>
          <Sidebar />
        </div>
        <div style={{ flex: "90%" }}>
          <NavbarDekstop />
          <AdminHome />
        </div>
      </div>
    )}
  </div>
  );
}
