import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaClosedCaptioning, FaTimes} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signatureNewIcon.png"
import Logo_Batik from "../../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import "../Styled.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import { FadeLoader, HashLoader, ScaleLoader } from "react-spinners";
import LoaderAction from "../../Loader/LoaderAction"
import "./Style.css"

export default function ModalRoleAdd(props) {
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(500);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(1);
  const token = localStorage.getItem("token");
  // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  const [loading, setLoading] = useState(false);
  const [getData, setGetData] = useState([]);
  const [getDataPayment, setGetDataPayment] = useState([]);
  const [getCode, setGetCode] = useState([]);
  const [getDataAppointmentById, setGetDataAppointmentById] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const GetResponseDataByIdAppointment = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`transaction-service/appointments/${props.idInvoiceAdmin}`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataAppointmentById(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataPayment = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`transaction-service/payments?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataPayment(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const generateCode = async () => {
    try {
      // e.preventDefault();
      // setLoading(true)
      const response = await API.post(`user-service/master-codes/generate`,
        {
          type: "invoice",
          object_code: getDataAppointmentById?.patient_data.code
        },
        fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetCode(response.data.data)
        // setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const [form, setForm] = useState({
    id_appointment: "",
    id_payment: "",
    date: "",
    status_assurance : ""
   });
 
   const {
     id_appointment,
     id_payment,
     date,
     status_assurance
   } = form;
  
   const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  
  useEffect(() => {
    GetResponseDataByIdAppointment()
    GetResponseDataPayment()
  }, [])

  useEffect(() => {
    if (getDataAppointmentById?.date) {
      generateCode()
        setForm({
          ...form, 
          date: `${getDataAppointmentById?.date}`,
      });
      }
  }, [getDataAppointmentById?.date])

  const currentDate = new Date();

  // Mendapatkan tahun (yy)
  const year = currentDate.getFullYear().toString()

  // Mendapatkan bulan (mm)
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');

  // Mendapatkan tanggal (dd)
  const dateConvert = currentDate.getDate().toString().padStart(2, '0');

  const handleSubmit = useMutation(async (e) => {
    try {
      e.preventDefault();
      setLoading(true)
    
      // Insert data for login process
      const response = await API.post("transaction-service/sales-invoices/store", {
        code: getCode?.number,
        id_appointment: props?.idInvoiceAdmin,
        id_patient: props?.idInvoiceAdminPatient,
        id_doctor: props?.idInvoiceAdminDoctor,
        id_admin: props?.idInvoiceIdAdmin,
        id_payment: form?.id_payment,
        date: `${year}/${month}/${dateConvert}`,
        status_assurance: form?.status_assurance
      }, fetchParams);
  
      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })
        // window.location.reload()
        props.GetResponseData()
        props.onHide()
        setLoading(false)
      }

    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false)
    }
  });

  const [isChecked, setIsChecked] = useState(false); 

  // Fungsi untuk mengubah status switch ketika diklik
  const toggleSwitch = () => {
    setIsChecked(prevState => !prevState);
  };

  return (
  <div>
    {loading && <LoaderAction/>}
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered 
    style={{ fontFamily: "sans-serif", border: "none" }}>  
      <div style={{width: "100%",display:"flex",padding:"10px 0px", backgroundColor:"#29B8FF"}}>
        <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", paddingLeft:"10px", 
        color:"white", fontWeight:"600"}}>
          Buat Invoice
        </div> 
        <div  style={{flex:"8%",fontSize:"30px",display:"flex",alignItems:"center",
        justifyContent:"center", color:"white"}}>
          <FaTimes onClick={() => setProopsData(props.onHide)} style={{cursor:"pointer"}}/>
        </div> 
      </div>
      <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px",
      border:"none",backgroundImage:"transparent" }}>
      <Form onSubmit={(e) => handleSubmit.mutate(e)} className="mt-1" >

      <div style={{display:"flex", justifyContent:"start"}}> 
        <div className="mr-1" style={{display:"flex", alignItems:"center", fontSize:"15px", 
        fontWeight:"bold", fontStyle:"revert"}}>
          Asuransi
        </div>
        <div style={{display:"flex", alignItems:"center"}}>
          <label className="toggle-switch">
            <input type="checkbox" checked={isChecked} onChange={toggleSwitch} />
            <span className="switch"></span>
          </label>
        </div>
      </div>

    {isChecked === false ? 
      <div className="mt-3" style={{ display: "flex"}}>
        <div style={{flex:"20%", display:"flex",alignItems:"center"}}>
          Pilih Pembayaran
        </div>
        <div style={{ flex: "80%", display:"flex"}}>
          <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
          <select className="form-select" aria-label="Default select example" onChange={handleChange} 
          name="id_payment" style={{ textAlign:"", cursor:"pointer"}}>
              <option value="" hidden>Select</option>
              {getDataPayment.filter(user => user.name_payment_type !== "Asuransi").map((user, index) => (
                <option key={index} value={user.id}>{user.name}</option>
              ))}
          </select>
        </div>
      </div> 
    :
    <div>
      <div className="mt-3" style={{ display: "flex"}}>
        <div style={{flex:"20%", display:"flex",alignItems:"center"}}>
          Pilih Pembayaran
        </div>
        <div style={{ flex: "80%", display:"flex"}}>
          <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
          <select className="form-select" aria-label="Default select example" onChange={handleChange} 
          name="id_payment" style={{ textAlign:"", cursor:"pointer"}}>
              <option value="" hidden>Select</option>
              {getDataPayment.filter(user => user.name_payment_type === "Asuransi").map((user, index) => (
                <option key={index} value={user.id}>{user.name}</option>
              ))}
          </select>
        </div>
      </div>

      <div className="mt-3" style={{ display: "flex"}}>
        <div style={{flex:"20%", display:"flex",alignItems:"center"}}>
          Pilih Service
        </div>
        <div style={{ flex: "80%", display:"flex"}}>
          <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
          <select className="form-select" aria-label="Default select example" onChange={handleChange} 
          name="status_assurance" style={{ textAlign:"", cursor:"pointer"}}>
              <option value="" hidden>Select</option>
              <option value="full">Full</option>
              <option value="partial">Partial</option>
          </select>
        </div>
      </div>

      </div>
      }
      
        <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"end" }}>
          <div>
            <Button className="mt-4" type='submit' color='primary' block style={{ padding: "8px 10px", 
            fontSize: "12px", borderRadius: "5px"}}>
              Tambahkan
            </Button>
          </div>
        </div>
        </Form>
        
      </Modal.Body>
      {/* <Modal.Footer>
        <div style={{ display: "flex" }}>
          <Button onClick={props.onHide} style={{marginRight:"8px"}}>Close</Button>
        </div>
      </Modal.Footer> */}      
      </Modal>
    </div>
   
    );
}
  